import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';

const defaultState = {
    claim : null
};

const setClaim = (state, action) => {
    return updateObject(state, {
        claim: action.claim
    })
};

const claimReducer = (state = defaultState, action) => {
    switch(action.type){
        case actionTypes.SET_CLAIM: return setClaim(state, action);
        default: return state
    }
};

export default claimReducer
