import  {React,Component} from 'react';
import {Card, Container, Row} from "react-bootstrap";
import Slider from "react-slick";
import ico21 from "../../../assets/images/mathieu-neuilly-BTBAoU_taRU-unsplash.png";
import ico24 from "../../../assets/images/Group 69.png";
import ico22 from "../../../assets/images/muhammad-ruqiyaddin-5Dx248u4RZ0-unsplash.png";
import ico23 from "../../../assets/images/muhammad-ruqiyaddin-65v_6djIAFs-unsplash.png";

class Customers extends Component {
    render() {
    return (<Container>
        <Row className="d-flex justify-content-center m-0">
            <h1 className="title_partners">Our Happy Customers</h1>
        </Row> 
        <Row className="justify-content-center cust_carousel px-5">
            <div className="owl-carousel">
                <Card className="py-4">
                    <Card.Body>
                        <div className="text-center">
                            <img className="cust_img mr-auto ml-auto" src={ico21} alt="" srcSet="" />
                            <img className="cust_img mt-2 mr-auto ml-auto" src={ico24} alt="" srcSet="" />
                            <h5 className="font-weight-bold my-2">Alex Hu</h5>
                            <p>
                                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                consequat. "
                            </p>
                        </div>
                    </Card.Body>
                </Card>
                <Card className="py-4">
                    <Card.Body>
                        <div className="text-center">
                            <img className="cust_img mr-auto ml-auto" src={ico22} alt="" srcSet="" />
                            <img className="cust_img mt-2 mr-auto ml-auto" src={ico24} alt="" srcSet="" />
                            <h5 className="font-weight-bold my-2">Miya</h5>
                            <p>
                                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                consequat. "
                            </p>
                        </div>
                    </Card.Body>
                </Card>
                <Card className="py-4">
                    <Card.Body>
                        <div className="text-center">
                            <img className="cust_img mr-auto ml-auto" src={ico23} alt="" srcSet="" />
                            <img className="cust_img mt-2 mr-auto ml-auto" src={ico24} alt="" srcSet="" />
                            <h5 className="font-weight-bold my-2">Aida</h5>
                            <p>
                                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                consequat. "
                            </p>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </Row>
    </Container>);
    }
}

export default Customers;
