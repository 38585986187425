import userReducer from "../redux/reducers/userReducer"
import productReducer from "../redux/reducers/productReducer"
import {combineReducers} from "redux";
import {progress} from 'react-redux-progress/reducer'
import quotesReducer from "./reducers/quotesReducer";
import policyReducer from "./reducers/policyReducer";
import claimReducer from "./reducers/claimReducer";
import universalReducer from "./reducers/universalReducer";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createTransform } from 'redux-persist';
import {parse, stringify} from 'flatted';
import storageSession from 'redux-persist/lib/storage/session'


const userPersistConfig = {
  key: 'userReducer',
  storage: storageSession,
  whitelist: ['user', 'accessToken']
}

export const transformCircular = createTransform(
    (inboundState, key) => stringify(inboundState),
    (outboundState, key) => parse(outboundState),
)

const productPersistConfig = {
  key: 'productReducer',
  storage: storageSession,
  whitelist: ['optionalPricing', 'optionalCovers', 'selectedProduct', 'searchFilter', 'productList'],
  transforms: [transformCircular]
}

const quotesPersistConfig = {
  key: 'quotesReducer',
  storage: storageSession,
  whitelist: ['quotes', 'worker', 'questionAns', 'account']
}

const rootReducer = combineReducers({
  userReducer : persistReducer(userPersistConfig, userReducer),
  productReducer : persistReducer(productPersistConfig, productReducer),
  quotesReducer : persistReducer(quotesPersistConfig, quotesReducer),
  policyReducer,
  claimReducer,
  universalReducer,
  progress
});

export default rootReducer;
