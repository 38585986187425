import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';

const defaultState = {
    policy : null,
    status : null,
};

const setPolicy = (state, action) => {
    return updateObject(state, {
        policy: action.policy
    })
};

const setStatus = (state, action) => {
    return updateObject(state, {
        status: action.status
    })
};

const policyReducer = (state = defaultState, action) => {
    switch(action.type){
        case actionTypes.SET_POLICY: return setPolicy(state, action);
        case actionTypes.SET_STATUS: return setStatus(state, action);
        default: return state
    }
};

export default policyReducer
