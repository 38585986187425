import React, {Component, Suspense} from "react";
import {routes} from "./routes";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Layout from "./hoc/Layout/Layout";
import {connect} from "react-redux";
import Navbar from "./components/Navbar/Navbar";
import agiliuxAPI from "./services/agiliuxAPI";


class App extends Component {
    render() {
        return (
            <Layout>
                <BrowserRouter basename="/">
                    <Navbar></Navbar>
                    <Suspense fallback={<div>Loading..</div>}>
                        <Switch>
                            {routes.map((route, i) => (
                                (route.authGuard && !this.props.isAuthenticated) ? null : <Route key={i} {...route} />
                            ))}
                        </Switch>
                    </Suspense>
                </BrowserRouter>
            </Layout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.userReducer.accessToken != null,
    };
};

export default connect(mapStateToProps)(App, agiliuxAPI);
