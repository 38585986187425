import React, {Component} from "react";
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import {withRouter} from "react-router-dom";
import {Link} from 'react-router-dom';
import {Element} from "react-scroll";
import ico1 from "../../../assets/images/Group 99.png";
import maidIcon from "../../../assets/images/036-medical-insurance-4.png";
import medicalIcon from "../../../assets/images/042-hands.png";
import homeIcon from "../../../assets/images/024-home.png";
import carIcon from "../../../assets/images/002-car-insurance.png";
import downArrow from "../../../assets/images/two-down-arrows.png";
import waveBG from "../../../assets/images/Group-1925.png";
import travelIcon from "../../../assets/images/Group 2059.png";
import leftDotted from "../../../assets/images/Group 100.png";
import chatIcon from "../../../assets/images/conversation@2x.png";
import {auth} from "../../../redux/actions";
import {messagesCleanUp} from "../../../redux/actions/userActions";
import {connect} from "react-redux";
import {requestProductList} from "../../../redux/actions/productActions";
import { ImageBackground } from 'react';


class About extends Component {
    state = {
        productData: [],
        productItem: []
    };

    componentDidMount() {
        // this.props.requestProductList();
        localStorage.removeItem("process")
        localStorage.removeItem("policy_id");
        localStorage.removeItem("quote_id");
    }

    render() {
        //console.log(this.state.productData);
        return (
            <div className="aboutWrapper">
            <Element name="about" className="home_main d-flex justify-content-center"
            style={{
                backgroundImage: `url(${waveBG})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'bottom',
            }}>

                <Container fluid className="px-5">
                    <Row className="d-flex align-items-center">
                    <Col sm={12} md={12} lg={12}>
                            <h5 className="home-text text-center mb-3 mt-2">
                                <strong>Get the best insurance plans and buy them instantly.{" "}
                                </strong>
                            </h5>
                            <h4 className="home-sub-text text-center">
                                <strong ><span className="text-primary">Instant</span> Quote. <span className="text-primary">Incredible</span> Prices. <span className="text-primary">Big</span> Savings.{" "}
                                </strong>
                            </h4>

                            {/* <div className="left-dotted">
                                <img  src={leftDotted} alt="" srcSet="" />
                                </div> */}





                            {/*
                            <Row className="plan-card">
                                {this.props.productList.map((item) => (
                                    <Col sm={12} lg={4} md={4} key = {item.id}>
                                        <Card>
                                            <div className="tag">
                                                <i className="far fa-star"></i>&nbsp;Great Value
                                            </div>
                                            <Card.Body className="d-flex flex-column justify-content-between">
                                                <>
                                                    <p className="mx-auto font-weight-bold">{item.productname}</p>
                                                    <p className="mx-auto text-primary">from RM{item.pricing.premium}/Year</p>
                                                    {item.itemData.map((itemPoint) => (
                                                        <Row className="my-2 mx-0" key = {itemPoint}>
                                                            <Col sm={2} md={2} lg={2} className="col-2 p-0">
                                                                <i className="fas fa-check-circle"></i>
                                                            </Col>
                                                            <Col className="px-0">{itemPoint}</Col>
                                                        </Row>))}
                                                    <a onClick={() => {
                                                            localStorage.setItem("item_id", item.id);
                                                            if(item.id === "14x2133"){
                                                                this.props.history.push("/InsuranceDetails/" + item.id);
                                                            }
                                                        }}
                                                        className={item.id === "14x2133" ? "mx-auto my-3 font-weight-bold text-primary" : "mx-auto my-3 font-weight-bold text-secondary disabled"}>
                                                        More Info
                                                    </a>
                                                </>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                            */}
                        </Col>
                    </Row>
                    <Row className="justify-content-around mt-3">
                    <Col className="insurance-icon" xs={12} sm={6} md={3} lg={3}>
                                    <img src={maidIcon} className="img-fluid homepage-icons"></img>
                                    <p><b>Maid Insurance</b></p>
                                <Link to="/maid"><Button>Check Prices</Button></Link>
                                <h6 className="startingLabel pt-2 mb-0">FROM S$15/MO</h6>
                            </Col>
                      {/* <Col className="insurance-icon" xs={12} sm={6} md={3} lg={3}>
                        <img src={maidIcon} className="img-fluid homepage-icons"></img>
                        <p><b>Maid Insurance 2</b></p>
                        <Link to="/maid"><Button>Check Prices</Button></Link>
                        <h6 className="startingLabel pt-2 mb-0">FROM S$15/MO</h6>
                      </Col> */}
                            {/* <Col className="insurance-icon" xs={12} sm={6} md={3} lg={3}>
                                    <img src={medicalIcon} className="img-fluid homepage-icons"></img>
                                    <p><b>Medical Insurance</b></p>
                                    <Button>Check Prices</Button>
                                    <h6 className="startingLabel pt-2 mb-0">FROM S$20/MO</h6>
                            </Col>

                            <Col className="insurance-icon" xs={12} sm={6} md={3} lg={3}>
                                    <img src={homeIcon} className="img-fluid homepage-icons"></img>
                                    <p><b>Home Insurance</b></p>
                                    <Button>Check Prices</Button>
                                    <h6 className="startingLabel pt-2 mb-0">FROM S$10/MO</h6>
                            </Col> */}

                            <Col className="insurance-icon" xs={12} sm={6} md={3} lg={3}>
                                    <img src={carIcon} className="img-fluid homepage-icons"></img>
                                    <p><b>Motor Insurance</b></p>
                                    <Button>Check Prices</Button>
                                    <h6 className="startingLabel pt-2 mb-0">FROM S$12/MO</h6>
                            </Col>
                            <Col className="insurance-icon" xs={12} sm={6} md={3} lg={3}>
                                    <img src={travelIcon} className="img-fluid homepage-icons"></img>
                                    <p><b>Travel Insurance</b></p>
                                    <Button>Check Prices</Button>
                                    <h6 className="startingLabel pt-2 mb-0">FROM S$13/MO</h6>
                            </Col>
                    </Row>
                    <Row className="down-arrow mt-1">
                        <Col className="text-center">
                            <img src={downArrow}></img>
                        </Col>

                    </Row>
                </Container>
                <Container>
                    <div className="chatBot"><a href="#contact">
                    <img src={chatIcon} className="img-fluid p-3"></img></a>
                    </div>
                </Container>

            </Element>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        productList: state.productReducer.productList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        auth: (email, password) => dispatch(auth(email, password)),
        messagesCleanUp: () => dispatch(messagesCleanUp()),
        requestProductList: () => dispatch(requestProductList()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(About));
