import React from 'react';

const Loader = () => {
  return (
    <div style={{
      width: '100vw',
      height: '100vh',
      zIndex: 99999,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'rgba(255,255,255, 0.9)',
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <img src="./loader.gif" />
      <div style={{
        textAlign: 'center',
        fontSize: 20,
        fontWeight: 600,
      }}>
        We are processing your request.<br />
        Please be patient and do not navigate away.
      </div>
    </div>
  );
};

export default Loader;
