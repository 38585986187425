import React, {Component} from "react";
import {connect} from "react-redux";
import {auth} from "../../../redux/actions";
import {Col, Form, InputGroup, Row} from "react-bootstrap";
import {Divider} from "antd";
import {SIGN_UP, OTP} from "./AuthModelType";
import {checkValidity, updateObject} from "../../../shared/utility";
import {messagesCleanUp} from "../../../redux/actions/userActions";

class LoginForm extends Component {

    state = {
        formIsValid: false,
        loginForm: {
            email: {
                value: '',
                validation: {
                    required: true,
                    isEmail: true
                },
                valid: false,
                touched: false
            },
            password: {
                value: '',
                validation: {
                    required: true,
                    minLength: 6
                },
                valid: false,
                touched: false
            }
        },
    };

    _login = (e) => {
        e.preventDefault();
        console.log("Authentication [ auth redux called ]");
        this.props.auth(this.state.loginForm.email.value, this.state.loginForm.password.value)
    };

    inputChangedHandler = ( event ) => {
        const controlName = event.target.name;
        const updatedControls = updateObject( this.state.loginForm, {
            [controlName]: updateObject( this.state.loginForm[controlName], {
                value: event.target.value,
                valid: checkValidity( event.target.value, this.state.loginForm[controlName].validation ),
                touched: true
            } )
        } );
        console.log(updatedControls);
        this.setState( {
            loginForm: updatedControls,
            formIsValid : updatedControls.email.valid && updatedControls.password.valid
        } );
        this.props.messagesCleanUp();
    }

    isValid = (form) => {
        return !form.touched || (form.validation &&  form.valid);
    }

    render() {
        return (
            <Form role="form" id="userLogin" onSubmit={this._login}>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <InputGroup hasValidation>
                        <Form.Control
                            placeholder="Enter email"
                            type="email"
                            name="email"
                            onChange={this.inputChangedHandler}
                            isInvalid={ !this.isValid(this.state.loginForm.email)}
                        />
                    </InputGroup>
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                    <Form.Label>OTP</Form.Label>
                    <InputGroup hasValidation>
                        <Form.Control
                            type="password"
                            name="password"
                            onChange={this.inputChangedHandler}
                            isInvalid={ !this.isValid(this.state.loginForm.password)}
                            placeholder="Enter OTP"
                        />
                    </InputGroup>
                </Form.Group>
                {/* <Form.Group controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Check me out" />
                </Form.Group> */}
                <button className="loadingButton btn btn-primary" type="submit" disabled = {this.props.loading || !this.state.formIsValid}>
                    {this.props.loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : null}
                    Log In
                </button>
                {/* <Row className="mt-3">
                    <Col>
                        <a href="javascript:void(0)" onClick={() => this.props.modelChanged(SIGN_UP)}>
                            <small>Don't have an account? Sign up here</small>
                        </a>
                    </Col>
                </Row> */}
                {/* <Divider orientation="left">Or Login Using</Divider>
                <h2>
                    <i className="fab fa-google mr-5 text-lg text-primary"></i>{" "}
                    <i className="fab fa-facebook text-lg text-primary"></i>
                </h2> */}
                <Row className="mt-3">
                    <Col xs="6"></Col>
                    <Col className="text-right" xs="6">
                        <a href="javascript:void(0)" onClick={() => this.props.modelChanged(OTP)}>
                            <small>Get OTP</small>
                        </a>
                    </Col>
                </Row>
            </Form>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.userReducer.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        auth: (email, password) => dispatch(auth(email, password)),
        messagesCleanUp: () => dispatch(messagesCleanUp()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
