import React, { Component } from "react";
import DefaultLogo from "../../assets/images/Skype_Picture_2021_05_18T13_38_32_676Z.jpeg";
import LogoImage from "../Navbar/Logo/LogoImage"
import WhiteLogo from "../../assets/images/AWG-logo-white.png";


class Footer extends Component {
  state = {};
  render() {
    return (
      <>
        <footer className="pt-5 pb-4" id="contact">
          <div className="container">
            <div className="row">
            <div className="col-lg-2 col-md-2 col-sm-6  mb-2 brand">
            <img className="img-fluid" src={WhiteLogo} alt="" />
                </div>
              <div className="col-lg-4 col-md-4 col-sm-6  mb-4">
                <h5 className="mb-0 font-weight-bold text-white">ABOUT US </h5>

                {/* <img src={DefaultLogo} alt="" /> */}
                {/* <p className="mb-4">Etiam laoreet in ex quis efficitur.</p> */}
                <ul >
                  <li>
                    <div className="row">

                      <div className="col-12">

                        <p>
                          {/* At AWG Insurance Brokers, we know that close and effective relationships matter and we are committed to a long-term relationship as your trusted insurance broker */}
                          At AWG Insurance Brokers, we know that close and effective relationships matter and we are committed to a long-term relationship as your trusted insurance broker
                        </p>
                      </div>
                    </div>
                  </li>


                </ul>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6  mb-4">

                {/* <p className="mb-4">Etiam laoreet in ex quis efficitur.</p> */}
                <ul >
                  <li>
                    <div className="row">
                      {/* <div className="col-1">
                        <i className="fas fa-map-marker"></i>
                      </div> */}
                      <div className="col-10">
                        <h5 className="font-weight-bold mb-0 text-white">AWG Insurance Brokers Pte. Ltd.</h5>

                        <p>
                          Tel: +65.6294 6688<br />
                          Fax: +65 6282 6188
                          / 6223 4133<br />
                          WhatsApp: +65.9113 2122
                          <br />
                          E-mail: awg@awginsurance.com<br />
                          Address: 240 Macpherson Road #08-01 Pines Building Singapore 348574



                        </p>
                      </div>
                    </div>
                  </li>


                </ul>
              </div>
              
              <div className="col-lg-2 col-md-2 col-sm-6  mb-2">
                <h5 className="mb-4 font-weight-bold text-white">Social Media</h5>
                {/* <p className="mb-4">Etiam laoreet in ex quis efficitur.</p> */}
                <ul>
                  <li><i className="fab fa-facebook"></i>
                    <a href="https://www.facebook.com/InsuranceBrokersServices/" target="_blank">Facebook</a>
                  </li>
                  <li><i className="fab fa-linkedin" ></i>
                    <a href="https://www.linkedin.com/company/awg-insurance-brokers-pte-ltd/" target="_blank">Linkedin</a>
                  </li>
                  {/* <li>
                    <i className="fab fa-instagram-square" ></i>
                    <a href="">Instagram</a>
                  </li>
                  <li>
                    <i className="fab fa-youtube-square" ></i>
                    <a href="">Youtube</a>
                  </li> */}



                </ul>
              </div>




            </div>
            <div className="row">
              <div className="col-md-12 ">
                <div className="text-center text-white"><a href="https://www.awginsurance.com/privacy-statement/" target="_blank">Privacy Statement</a> | <a href="https://www.awginsurance.com/data-use-statement/" target="_blank">Data Use Statement</a></div>
              </div>
            </div>
          </div>

        </footer>
        <section className="copyright">
          <div className="container">
            <div className="row">
              <div className="col-md-12 ">
                <div className="text-center text-white">&copy; {(new Date().getFullYear())} AWG Insurance. All Rights Reserved.</div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Footer;
