import React, {Component} from "react";
import {connect} from "react-redux";
import {authCheckUsername, authSignUp, messagesCleanUp} from "../../../redux/actions/userActions";
import {Col, Form, InputGroup, Row} from "react-bootstrap";
import {LOGIN} from "./AuthModelType";
import {checkValidity, updateObject} from "../../../shared/utility";

class RegisterForm extends Component {

    state = {
        isInvalidEmail: false,
        isConfirmPassword: false,
        isExist: false,
        formIsValid: false,
        registerForm: {
            email: {
                value: '',
                validation: {
                    error : "enter valid email address",
                    required: true,
                    isEmail: true
                },
                valid: false,
                touched: false
            },
            firstname: {
                value: '',
                validation: {
                    error :"First name is required",
                    required: true
                },
                valid: false,
                touched: false
            },
            lastname: {
                value: '',
                validation: {
                    error :"Last name is required",
                    required: true
                },
                valid: false,
                touched: false
            },
            password: {
                value: '',
                validation: {
                    error :"Password is required",
                    required: true,
                    isNumeric:true
                },
                valid: false,
                touched: false
            },
            confirmpassword: {
                value: '',
                validation: {
                    error :"Same Password is required",
                    required: true,
                    isNumeric:true

                },
                valid: false,
                touched: false
            }
        }
    };

    signup = (e) => {
        e.preventDefault();
        this.props.authSignUp(
            this.state.registerForm.email.value,
            this.state.registerForm.firstname.value,
            this.state.registerForm.lastname.value,
            this.state.registerForm.email.value,
            this.state.registerForm.password.value);
    };

    //Check email
    handleChangesEmail = (e) => {
        e.preventDefault();
        this.props.authCheckUsername(e.target.value)
            .then((response) => {
                console.log(response.data.isUserExist);
                this.setState( {
                    isExist : response.data.isUserExist == true
                });
            });
        this.inputChangedHandler(e);
    };

    handleChangesPassword = (e) => {
        e.preventDefault();
        if (e.target.name == "password") {
            this.setState( {
                isConfirmPassword: this.state.registerForm.confirmpassword.value == e.target.value
            });
        } else if (e.target.name == "confirmpassword") {
            this.setState( {
                isConfirmPassword: this.state.registerForm.password.value == e.target.value
            });
        }
        this.inputChangedHandler(e);
    };

    inputChangedHandler = ( event ) => {
        const controlName = event.target.name;
        const updatedControls = updateObject( this.state.registerForm, {
            [controlName]: updateObject( this.state.registerForm[controlName], {
                value: event.target.value,
                valid: checkValidity( event.target.value, this.state.registerForm[controlName].validation ),
                touched: true,
            } )
        } );
        this.setState( {
            registerForm: updatedControls,
            isExist: false,
            formIsValid : updatedControls.email.valid &&
                updatedControls.firstname.valid &&
                updatedControls.lastname.valid &&
                updatedControls.password.valid
        } );
        this.props.messagesCleanUp();
    }

    isValid = (form) => {
        return !form.touched || (form.validation &&  form.valid);
    }

    render() {
        return (
            <Form role="form" id="userRegister" onSubmit={this.signup}>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <InputGroup hasValidation>
                        <Form.Control
                            type="email"
                            name="email"
                            onChange={this.inputChangedHandler}
                            onBlur = { this.handleChangesEmail }
                            placeholder="Enter email"
                            isInvalid={ !this.isValid(this.state.registerForm.email) || this.state.isExist }/>
                        <Form.Control.Feedback type="invalid">
                            { !this.isValid(this.state.registerForm.email) ?
                                this.state.registerForm.email.validation.error :
                                this.state.isExist ? <>Your email is already registered. Please<a href="javascript:void(0)" onClick={() => this.props.modelChanged(LOGIN)}> Login</a></> : null
                            }
                        </Form.Control.Feedback>
                    </InputGroup>
                    <Form.Text className="text-muted">
                        Existing policy holder’s use same email given for previous policy.
                    </Form.Text>
                </Form.Group>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>First Name</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                placeholder="Enter first name"
                                type="text"
                                name="firstname"
                                onChange={this.inputChangedHandler}
                                isInvalid={ !this.isValid(this.state.registerForm.firstname) }
                            />
                            <Form.Control.Feedback type="invalid">
                                {this.state.registerForm.firstname.validation.error}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>Last Name</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                placeholder="Enter last name"
                                type="text"
                                name="lastname"
                                onChange={this.inputChangedHandler}
                                isInvalid={ !this.isValid(this.state.registerForm.lastname) }
                            />
                            <Form.Control.Feedback type="invalid">
                                {this.state.registerForm.lastname.validation.error}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Form.Row>
                <Form.Group controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <InputGroup hasValidation>
                        <Form.Control
                            name="password"
                            type="password"
                            placeholder="Password"
                            onChange={this.handleChangesPassword}
                            isInvalid={ !this.isValid(this.state.registerForm.password) }
                        />
                        <Form.Control.Feedback type="invalid">
                            {this.state.registerForm.password.validation.error}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                    <Form.Label>Confirm Password</Form.Label>
                    <InputGroup hasValidation>
                        <Form.Control
                            name="confirmpassword"
                            type="password"
                            placeholder="Password"
                            onChange={this.handleChangesPassword}
                            isInvalid={ this.state.registerForm.confirmpassword.touched && this.state.registerForm.confirmpassword.valid && !this.state.isConfirmPassword }
                        />
                        <Form.Control.Feedback type="invalid">
                            {this.state.registerForm.confirmpassword.validation.error}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>

                <button className="loadingButton btn btn-primary" type="submit"
                        disabled={this.props.loading || !this.state.formIsValid  || this.state.isExist ||!this.state.isConfirmPassword}>
                    {this.props.loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : null}
                    Sign Up
                </button>
                <Row className="mt-3">
                    <Col xs="6"></Col>
                    <Col className="text-right" xs="6">
                        <a href="javascript:void(0)" onClick={() => this.props.modelChanged(LOGIN)}>
                            <small>Back to Login</small>
                        </a>
                    </Col>
                </Row>
            </Form>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.userReducer.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        authSignUp: (username, firstname, lastname, email, password) => dispatch(authSignUp(username, firstname, lastname, email, password)),
        authCheckUsername: (email) => dispatch(authCheckUsername(email)),
        messagesCleanUp: () => dispatch(messagesCleanUp()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
