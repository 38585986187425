import React, {Component} from "react";
import {Col, Form, Row, Card} from "react-bootstrap";
import {checkValidity, updateObject} from "../../../shared/utility";
import {setUser} from "../../../redux/actions/userActions";
import {requestCitiesByState, requestCountries, requestStatesByCountry} from "../../../redux/actions/universalActions";
import {connect} from "react-redux";
import {setQuestionAns} from "../../../redux/actions/quotesActions";
import quotesReducer from "../../../redux/reducers/quotesReducer";

class QuestionAnsForm extends Component {

    inputChangedHandler = (event) => {
        const controlName = event.target.name;
        const updatedControls = updateObject(this.props.questionAns, {
            [controlName]: event.target.value
            });
        console.log(updatedControls)
        this.props.setQuestionAns(updatedControls);
    }

    render() {
        return (
            <Card>
                <Card.Body>
                    <Card.Title className={"mb-5"}><b>Answer the following question(Options)</b></Card.Title>
                    <Col lg={12} className={"pl-0 mb-5"}>
                        <Form.Label>
                            Have you ever had insurance cancelled?
                        </Form.Label>
                        <div className="btn-group-toggle"  data-toggle="buttons">
                            <label className={'Yes' === this.props.questionAns.underwritingquestion_1 ?
                                "btn btn-choice btn-primary mr-2 active" : "btn btn-choice btn-primary mr-2"}>
                                <input
                                    type="radio"
                                    name="underwritingquestion_1"
                                    value="Yes"
                                    checked={'Yes' === this.props.questionAns.underwritingquestion_1}
                                    onChange={this.inputChangedHandler}
                                    autoComplete="off"
                                />{" "}
                                Yes
                            </label>

                            <label className={'No' === this.props.questionAns.underwritingquestion_1 ?
                                "btn btn-choice btn-primary mr-2 active" : "btn btn-choice btn-primary mr-2"}>
                                <input
                                    type="radio"
                                    name="underwritingquestion_1"
                                    value="No"
                                    checked={'No' === this.props.questionAns.underwritingquestion_1}
                                    onChange={this.inputChangedHandler}
                                    autoComplete="off"
                                />{" "}
                                No
                            </label>
                        </div>
                    </Col>

                    <Col lg={12} className={"pl-0 mb-5"}>
                        <Form.Label>
                            Has any insurance company denied your application before?
                        </Form.Label>
                        <div className="btn-group-toggle" data-toggle="buttons">
                            <label className={'Yes' === this.props.questionAns.underwritingquestion_2 ?
                                "btn btn-choice btn-primary mr-2 active" : "btn btn-choice btn-primary mr-2"}>
                                <input
                                    type="radio"
                                    name="underwritingquestion_2"
                                    value="Yes"
                                    checked={'Yes' === this.props.questionAns.underwritingquestion_2}
                                    onChange={this.inputChangedHandler}
                                    autoComplete="off"
                                />{" "}
                                Yes
                            </label>
                            <label className={'No' === this.props.questionAns.underwritingquestion_2 ?
                                "btn btn-choice btn-primary mr-2 active" : "btn btn-choice btn-primary mr-2"}>
                                <input
                                    type="radio"
                                    name="underwritingquestion_2"
                                    value="No"
                                    checked={'No' === this.props.questionAns.underwritingquestion_2}
                                    onChange={this.inputChangedHandler}
                                    autoComplete="off"
                                />{" "}
                                No
                            </label>
                        </div>
                    </Col>

                    <Col lg={12} className={"pl-0 mb-5"}>
                        <Form.Label>
                            Has your property had any flood damage in the past 5 years?
                        </Form.Label>
                        <div className="btn-group-toggle" data-toggle="buttons">
                            <label className={'Yes' === this.props.questionAns.underwritingquestion_3 ?
                                "btn btn-choice btn-primary mr-2 active" : "btn btn-choice btn-primary mr-2"}>
                                <input
                                    type="radio"
                                    name="underwritingquestion_3"
                                    value="Yes"
                                    checked={'Yes' === this.props.questionAns.underwritingquestion_3}
                                    onChange={this.inputChangedHandler}
                                />{" "}
                                Yes
                            </label>
                            <label className={'No' === this.props.questionAns.underwritingquestion_3 ?
                                "btn btn-choice btn-primary mr-2 active" : "btn btn-choice btn-primary mr-2"}>
                                <input
                                    type="radio"
                                    name="underwritingquestion_3"
                                    value="No"
                                    checked={'No' === this.props.questionAns.underwritingquestion_3}
                                    onChange={this.inputChangedHandler}
                                />{" "}
                                No
                            </label>
                        </div>
                    </Col>

                    <Col lg={12} className={"pl-0 mb-5"}>
                        <Form.Label>
                            Have you ever had insurance cancelled?
                        </Form.Label>
                        <div className="btn-group-toggle" data-toggle="buttons">
                            <label className={'Yes' === this.props.questionAns.underwritingquestion_4 ?
                                "btn btn-choice btn-primary mr-2 active" : "btn btn-choice btn-primary mr-2"}>
                                <input
                                    type="radio"
                                    name="underwritingquestion_4"
                                    value="Yes"
                                    autoComplete="off"
                                    checked={'Yes' === this.props.questionAns.underwritingquestion_4}
                                    onChange={this.inputChangedHandler}
                                />{" "}
                                Yes
                            </label>
                            <label className={'No' === this.props.questionAns.underwritingquestion_4 ?
                                "btn btn-choice btn-primary mr-2 active" : "btn btn-choice btn-primary mr-2"}>
                                <input
                                    type="radio"
                                    name="underwritingquestion_4"
                                    value="No"
                                    checked={'No' === this.props.questionAns.underwritingquestion_4}
                                    autoComplete="off"
                                    onChange={this.inputChangedHandler}
                                />{" "}
                                No
                            </label>
                        </div>
                    </Col>

                </Card.Body>
            </Card>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        questionAns: state.quotesReducer.questionAns,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setQuestionAns: (questionAns) => dispatch(setQuestionAns(questionAns)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(QuestionAnsForm);
