export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const checkValidity = (value, rules) => {
  let isValid = true;
  if (!rules) {
    return true;
  }

  if (rules.isDate && value) {
    isValid = Boolean(value) && value.isValid() && isValid;
  } else if (rules.required) {
    isValid = Boolean(value) && value.trim() !== "" && isValid;
  }

  if (rules.minLength && value) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength && value) {
    isValid = value.length <= rules.maxLength && isValid;
  }
  // console.log(rules);
  // console.log(rules.isNRIC, "rules.isNRIC");
  // console.log(rules.isIdCardNo, "rules.isIdCardNo");
  // console.log(value, "value");

  if (rules.isNRIC && rules.isIdCardNo && value) {
    const pattern = /^[S|T]\d{7}[a-zA-Z]{1}$/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isFIN && rules.isIdCardNo && value) {
    const pattern = /^[F|G]\d{7}[a-zA-Z]{1}$/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isSbNumber && value) {
    const pattern = /^[a-zA-Z0-9]{7}$/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isWorkPermit && value) {
    const pattern = /^0\s\d{8}$/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isPhone && value) {
    const pattern = /[6|8|9]\d{7}|\+65[6|8|9]\d{7}|\+65\s[6|8|9]\d{7}/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isEmail && value) {
    const pattern =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isNumeric && value) {
    const pattern = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    isValid = pattern.test(value) && isValid;
  }

  return isValid;
};

export const cleanUpObject = (obj) => {
  for (let propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ""
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

export const convertToDecimal = value => {
  return (parseFloat(value)).toFixed(2);
};
