import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';

const defaultState = {
    currentActiveTab : null,
    logo : null,
    loggedIn: false,
    user: {},
    accessToken: null,
    message : null,
    loading: false,
    authRedirectPath: '/',
    isLastRequestSuccess : false
}

const authStart = ( state, action ) => {
    return updateObject( state, { error: null, loading: true } );
};

const setAuthUser = ( state, action ) => {
    if(action.payload.length != 0){
        return updateObject( state, {
            ...state,
            loggedIn: true,
            user: updateObject( state.user , action.payload)
        });
    }else{
        return updateObject( state, {
            ...state,
            loggedIn: false,
            user: {}
        });
    }
};

const authSuccess = (state, action) => {
    return updateObject( state, {
        accessToken: action.accessToken,
        user: action.user,
        error: null,
        loading: false,
        message: action.message,
        isLastRequestSuccess :true
    } );
};

const authAutoLogin = (state, action) => {
    return updateObject( state, {
        accessToken: action.accessToken,
        user: action.user,
    } );
};

const authOTP = (state, action) => {
    return updateObject( state, {
        error: null,
        loading: false,
        message: action.message,
        isLastRequestSuccess :true
    } );
};

const authFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false,
        user: {},
        accessToken: null,
        message: action.message,
        isLastRequestSuccess : false
    });
};

const authLogout = (state, action) => {
    return updateObject(state, {
        token: null,
        user: {},
        message: null,
        loading: false,
        accessToken: null});
};

const messagesCleanUp = (state, action) => {
    return updateObject(state, {message: null, loading : false, isLastRequestSuccess : false});
};

const setAuthRedirectPath = (state, action) => {
    return updateObject(state, {
        authRedirectPath: action.path,
        message : null
    })
}

const setCurrentActiveTab = (state, action) => {
    return updateObject(state, {
        currentActiveTab: action.currentTab
    })
}

const setCompanyLogo = (state, action) => {
    return updateObject(state, {
        logo: action.logo
    })
}

const userReducer = (state = defaultState, action) => {
    switch(action.type){
        case actionTypes.SET_AUTH_USER: return setAuthUser(state, action);
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_AUTO_LOGIN: return authAutoLogin(state, action);
        case actionTypes.AUTH_OTP: return authOTP(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.AUTH_MESSAGE_CLEAN_UP: return messagesCleanUp(state, action);
        case actionTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state,action);
        case actionTypes.SET_CURRENT_ACTIVE_TAB: return setCurrentActiveTab(state,action);
        case actionTypes.SET_LOGO: return setCompanyLogo(state,action);
        default: return state
    }
}

export default userReducer
