import React from 'react';
//import Carousel from 'react-bootstrap/Carousel';
import {Col, Container, Row} from "react-bootstrap";
import ico26 from "../../../assets/images/partners/1.png";
import ico27 from "../../../assets/images/partners/2.png";
import ico28 from "../../../assets/images/partners/3.png";
import ico29 from "../../../assets/images/partners/4.png";
import ico30 from "../../../assets/images/partners/5.png";
import ico31 from "../../../assets/images/partners/6.png";
import ico32 from "../../../assets/images/partners/7.png";
import ico33 from "../../../assets/images/partners/8.png";
import partner1 from "../../../assets/images/partners/partner-AIG.png";
import partner2 from "../../../assets/images/partners/partner-Aviva.png";
import partner3 from "../../../assets/images/partners/partner-AXA.png";
import partner4 from "../../../assets/images/partners/partner-Chubb.png";
import partner5 from "../../../assets/images/partners/partner-etiqa.png";
import partner6 from "../../../assets/images/partners/partner-GreatEastern.png";
import partner7 from "../../../assets/images/partners/partner-HL.png";
import partner8 from "../../../assets/images/partners/partner-III.png";
import partner9 from "../../../assets/images/partners/partner-LI.png";
import partner10 from "../../../assets/images/partners/partner-lloyds.png";
import partner11 from "../../../assets/images/partners/partner-MSIG.png";
import partner12 from "../../../assets/images/partners/partner-ntuc.png";
import partner13 from "../../../assets/images/partners/partner-QBE.png";
import partner14 from "../../../assets/images/partners/partner-Taiping.png";
import partner15 from "../../../assets/images/partners/partner-tokio.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
    xl: {
      breakpoint: { max: 4000, min: 1200 },
      items: 4
    },
    lg: {
      breakpoint: { max: 1200, min: 992 },
      items: 4
    },
    md: {
      breakpoint: { max: 992, min: 768 },
      items: 3
    },
    sm: {
      breakpoint: { max: 768, min: 576 },
      items: 2
    },
    xs: {
        breakpoint: { max: 576, min: 0 },
        items: 1
      }
  };
const Partners = () => {
    return (<Container className="mx-0 pb-5 mb-5" prevIcon="none">
        <Row className="d-flex justify-content-center my-5">
            <h1 className="title_partners">Our Trusted Partners</h1>
        </Row> 
        <Carousel
        removeArrowOnDeviceType={["xl", "lg", "md" , "sm" , "xs"]} 
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={1500}
        responsive={responsive}
        className="mx-5">
            <div className="d-flex justify-content-center align-items-center">
            <img src={partner4}/>
            </div>
            <div className="d-flex justify-content-center align-items-center">
            <img src={partner5}/>
            </div>
            <div className="d-flex justify-content-center align-items-center">
            <img src={partner6}/>
            </div>
            <div className="d-flex justify-content-center align-items-center">
            <img src={partner3}/>
            </div>
            <div className="d-flex justify-content-center align-items-center">
            <img src={partner2}/>
            </div>
            <div className="d-flex justify-content-center align-items-center">
            <img src={partner1}/>
            </div>
            <div className="d-flex justify-content-center align-items-center">
            <img src={partner14}/>
            </div>
            <div className="d-flex justify-content-center align-items-center">
            <img src={partner7}/>
            </div>
            <div className="d-flex justify-content-center align-items-center">
            <img src={partner8}/>
            </div>
            <div className="d-flex justify-content-center align-items-center">
            <img src={partner9}/>
            </div>
            <div className="d-flex justify-content-center align-items-center">
            <img src={partner10}/>
            </div>
            <div className="d-flex justify-content-center align-items-center">
            <img src={partner11}/>
            </div>
            <div className="d-flex justify-content-center align-items-center">
            <img src={partner12}/>
            </div>
            <div className="d-flex justify-content-center align-items-center">
            <img src={partner13}/>
            </div>
            <div className="d-flex justify-content-center align-items-center">
            <img src={partner15}/>
            </div>

        </Carousel>
        
    {/*    <Carousel className="my-5" >
   <Carousel.Item interval={2000}>
    <Row className="d-flex justify-content-center text-center px-5">
        <Col className="col-sm-3">
            <img src={ico26}/>
        </Col>
        <Col className="col-sm-3">
            <img src={ico27}/>
        </Col>
        <Col className="col-sm-3">
            <img src={ico28}/>
        </Col>
        <Col className="col-sm-3">
            <img src={ico29}/>
        </Col>
    </Row>
  </Carousel.Item> 
  <Carousel.Item interval={2000}>
    <Row className="d-flex justify-content-center text-center px-5">
        <Col className="col-xs-6 col-sm-6 col-md-4 col-lg-3 ">
            <img src={partner4}/>
        </Col>
        <Col className="col-xs-6 col-sm-6 col-md-4 col-lg-3 ">
            <img src={partner5}/>
        </Col>
        <Col className="col-xs-6 col-sm-6 col-md-4 col-lg-3 ">
            <img src={partner6}/>
        </Col>
        <Col className="col-xs-6 col-sm-6 col-md-4 col-lg-3 ">
            <img src={partner3}/>
        </Col>
        
    </Row>
  </Carousel.Item>
  <Carousel.Item interval={2000}>
    <Row className="d-flex justify-content-center text-center px-5">
        <Col className="col-xs-6 col-sm-6 col-md-4 col-lg-3 ">
            <img src={partner2}/>
        </Col>
        <Col className="col-xs-6 col-sm-6 col-md-4 col-lg-3 ">
            <img src={partner1}/>
        </Col>
        <Col className="col-xs-6 col-sm-6 col-md-4 col-lg-3 ">
            <img src={partner14}/>
        </Col>
        <Col className="col-xs-6 col-sm-6 col-md-4 col-lg-3 ">
            <img src={partner7}/>
        </Col>
        
    </Row>
  </Carousel.Item>
  <Carousel.Item interval={2000}>
    <Row className="d-flex justify-content-center text-center px-5">
        <Col className="col-xs-6 col-sm-6 col-md-4 col-lg-3 ">
            <img src={partner8}/>
        </Col>
        <Col className="col-xs-6 col-sm-6 col-md-4 col-lg-3 ">
            <img src={partner9}/>
        </Col>
        <Col className="col-xs-6 col-sm-6 col-md-4 col-lg-3 ">
            <img src={partner10}/>
        </Col>
        <Col className="col-xs-6 col-sm-6 col-md-4 col-lg-3 ">
            <img src={partner11}/>
        </Col>
    </Row>
  </Carousel.Item>
  <Carousel.Item interval={2000}>
    <Row className="d-flex justify-content-center text-center px-5">
    <Col className="col-xs-6 col-sm-6 col-md-4 col-lg-3 ">
            <img src={partner12}/>
        </Col>
    <Col className="col-xs-6 col-sm-6 col-md-4 col-lg-3 ">
            <img src={partner13}/>
        </Col>
        <Col className="col-xs-6 col-sm-6 col-md-4 col-lg-3 ">
            <img src={partner15}/>
        </Col>
    </Row>
  </Carousel.Item>
  </Carousel>*/}
    </Container>);
}

export default Partners;
