import React from 'react';
import { Button, Col, Container, Form, Row, Alert } from "react-bootstrap";
import { checkValidity, updateObject } from "../../../shared/utility";
import ico25 from "../../../assets/images/Group 99.png";
import agiliuxAPI from "../../../services/agiliuxAPI";

class Contactus extends React.Component {

    state = {
        formIsValid: false,
        showSuccess: false,
        showError: false,
        contactForm: {
            user_name: {
                value: '',
                validation: {
                    required: true,
                    error : "Name is required",
                },
                valid: false,
                touched: false
            },
            email: {
                value: '',
                validation: {
                    required: true,
                    isEmail: true,
                    error : "Enter valid email address",
                },
                valid: false,
                touched: false
            },
            message:{
                value:'',
                validation:{
                    required:true,
                    error : "Message is required",
                },
                valid:false,
                touched:false
            }
        }
    }

    _submit = (e) => {
        e.preventDefault();
        var formData = {   
            name: this.state.contactForm.user_name.value,
            email: this.state.contactForm.email.value,
            description: this.state.contactForm.message.value,
            insuranceclass: '74x151557',
        }

        agiliuxAPI
            .post("crm/inquiry/create", formData)
            .then((res) => {
                console.log(res);
                this.setState({showSuccess: true})
                e.target.reset();
            })
            .catch((error) => {
                console.log(error);
                this.setState({showError: true})
            }
        );
    };

    inputChangedHandler = (event) => {
        const controlName = event.target.name;
        const updatedControls = updateObject(this.state.contactForm, {
            [controlName]: updateObject(this.state.contactForm[controlName], {
                value: event.target.value,
                valid: checkValidity(event.target.value, this.state.contactForm[controlName].validation),
                touched: true
            })
        });
    
        this.setState({
            contactForm: updatedControls,
            formIsValid: updatedControls.user_name.valid && updatedControls.email.valid && updatedControls.message.valid
        });
    }

    isValid = (form) => {
        return !form.touched || (form.validation && form.valid);
    }

    render() {
        return (<Container id="contact">
            <Row className="mt-5 m-0">
                <h1 className="title_contact">Have any feedbacks or questions</h1>
            </Row>
            <Row className="mb-5 m-0">
                <h1 className="title_contact2">Contact Us</h1>
            </Row>
            <Row>
                <Col lg={5}>
                    <img src={ico25} alt="" srcSet="" className="w-100" />
                </Col>
                <Col>
                    {(this.state.showSuccess == true) ? 
                        <Alert className="mt-2" onClick={() => this.setState({showSuccess: false})} variant="success" dismissible>
                        Thank you for your enquiry! Your message has been sent successfully.
                        </Alert> : null
                    }
                    {(this.state.showError == true) ? 
                        <Alert className="mt-2" onClick={() => this.setState({showError: false})} variant="danger" dismissible>
                        Opps, please try again.
                        </Alert> : null
                    }
                    
                    <Form onSubmit={this._submit}>
                        <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Your Name</Form.Label>
                            <Form.Control name="user_name" type="text"
                                onChange={this.inputChangedHandler}
                                isInvalid={!this.isValid(this.state.contactForm.user_name)} />
                                  <Form.Control.Feedback type="invalid">
                                {this.state.contactForm.user_name.validation.error}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Your Email Address</Form.Label>
                            <Form.Control name="email" type="email" onChange={this.inputChangedHandler}
                                isInvalid={!this.isValid(this.state.contactForm.email)} />
                                     <Form.Control.Feedback type="invalid">
                                {this.state.contactForm.email.validation.error}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Your Message</Form.Label>
                            <Form.Control name="message" as="textarea" rows={3} onChange={this.inputChangedHandler}
                                isInvalid={!this.isValid(this.state.contactForm.message)} />
                                     <Form.Control.Feedback type="invalid">
                                {this.state.contactForm.message.validation.error}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Button variant="primary" type="submit" disabled = {!this.state.formIsValid}>
                            Submit
                    </Button>
                    </Form>
                </Col>
            </Row>
        </Container>);
    }
}

export default Contactus;
