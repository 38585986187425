export const ADD_INGREDIENT = 'ADD_INGREDIENT';
export const REMOVE_INGREDIENT = 'REMOVE_INGREDIENT';
export const SET_INGREDIENTS = 'SET_INGREDIENTS';
export const FETCH_INGREDIENTS_FAILED = 'FETCH_INGREDIENTS_FAILED';

export const PURCHASE_BURGER_START = 'PURCHASE_BURGER_START';
export const PURCHASE_BURGER_SUCCESS = 'PURCHASE_BURGER_SUCCESS';
export const PURCHASE_BURGER_FAIL = 'PURCHASE_BURGER_FAIL';
export const PURCHASE_INIT = 'PURCHASE_INIT';

export const FETCH_ORDERS_START = 'FETCH_ORDERS_START';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAIL = 'FETCH_ORDERS_FAIL';

export const SET_AUTH_USER = 'SET_AUTH_USER';
export const AUTH_AUTO_LOGIN = 'SET_AUTH_USER';
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_OTP = 'AUTH_OTP';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_MESSAGE_CLEAN_UP = 'AUTH_MESSAGE_CLEAN_UP';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';
export let SET_CURRENT_ACTIVE_TAB = "SET_CURRENT_ACTIVE_TAB";
export let SET_LOGO = "SET_LOGO";
export let SET_SEARCH_FILTER = "SET_SEARCH_FILTER";
export let CLEAR_UP_SEARCH_FILTER = "CLEAR_UP_SEARCH_FILTER";

export let SET_PRODUCT_LIST = "SET_PRODUCT_LIST";
export let SET_OPTIONAL_PRICING = "SET_OPTIONAL_PRICING";
export let RESET_OPTIONAL_COVERS = 'RESET_OPTIONAL_COVERS';
export let SET_OPTIONAL_COVERS = 'SET_OPTIONAL_COVERS';
export let ADD_PRODUCT_LIST = "ADD_PRODUCT_LIST";
export let UPDATE_PRODUCT_LIST = "UPDATE_PRODUCT_LIST";
export let SET_SELECTED_PRODUCT = "SET_SELECTED_PRODUCT";
export let SET_FAQ_PRODUCT = "SET_FAQ_PRODUCT";

export let SET_BASIC_COVERAGE_INFO= "SET_BASIC_COVERAGE_INFO";
export let SET_QUOTES= "SET_QUOTES";
export let SET_WORKER= "SET_WORKER";
export let SET_ACCOUNT= "SET_ACCOUNT";
export let SET_QUESTION_ANS= "SET_QUESTION_ANS";
export let SET_POLICY= "SET_POLICY";
export let SET_STATUS= "SET_STATUS";
export let SET_CLAIM= "SET_CLAIM";
export let SET_COUNTRIES= "SET_COUNTRIES";
export let SET_STATES= "SET_STATES";
export let SET_CITIES= "SET_CITIES";

export const ETIQA_APPLICATION_REF = "ETIQA_APPLICATION_REF";
export const MSIG_APPLICATION_REF = "MSIG_APPLICATION_REF";
