import agiliuxAPI from "../../services/agiliuxAPI";
import * as actionTypes from './actionTypes';
import jwt_decode from "jwt-decode";
import {cleanUpObject} from "../../shared/utility";
import moment from "moment";

export const authStart = () => ({type: actionTypes.AUTH_START});

export const authFail = (message) => ({type: actionTypes.AUTH_FAIL, message: message});

export const authOTP = (message) => ({type: actionTypes.AUTH_OTP, message: message});

export const setAuthRedirectPath = (path) =>  ({type: actionTypes.SET_AUTH_REDIRECT_PATH, path: path});

export const setUser = (payload) => ({ type: actionTypes.SET_AUTH_USER, payload })

export const authLogout = () => ({ type: actionTypes.AUTH_LOGOUT })

export const setLogo = (logo) => ({ type: actionTypes.SET_LOGO, logo : logo })

export const messagesCleanUp = () => ({ type: actionTypes.AUTH_MESSAGE_CLEAN_UP })

export const  setCurrentActiveTab = (tabName) => {
    return { type: actionTypes.SET_CURRENT_ACTIVE_TAB, currentTab : tabName };
}

export const autoLogin = () => dispatch => {
    const user = localStorage.getItem("user");
    //const isAuthenticated =  Object.keys(user).length !== 0;
    const accessToken = localStorage.getItem("accessToken");
    if(user != null && accessToken != null ) {
        const extractUser = JSON.parse(user);
        const extractToken = jwt_decode(accessToken);
        if (extractToken.exp < Date.now() / 1000) {
            dispatch(logout(extractToken));
        } else {
            dispatch(fetchUserDetails(extractUser.id))
        }
    } else {
        localStorage.clear();
    }
}

export const authCheckUsername = (username) => dispatch => {
    return agiliuxAPI.get("/crm/user/checkUsername/" +  username);
}

export const signUserUp = (data) => dispatch => {
    localStorage.setItem("user",  JSON.stringify(data));
    dispatch(setUser(data));
}

export const authSuccess = (accessToken, user, message) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        accessToken: accessToken,
        user: user,
        message:message
    };
};

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000);
    };
};

export const fetchUserDetails = (userId) => {
    return dispatch => {
        dispatch(authStart());
        dispatch(startAction('-'));
        agiliuxAPI.post("crm/user/detail", {id : userId})
            .then(response => {
                const user = response.data['contacts'][0];
                dispatch(setUser(user));
                dispatch(stopAction("-"));
            })
            .catch(err => {
                // dispatch(authLogout());
                localStorage.clear();
                dispatch(stopAction("-"));
            });
    }
}

export const fetchUser = (data) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            // if(localStorage.getItem("user") == null){
            //    localStorage.setItem("user", JSON.stringify(data));
            // }
            dispatch(setUser(data));
            resolve();
        });
    }
}

export const authSignUp = (username,firstname,lastname,email,password) => dispatch => {
    dispatch(authStart());
    agiliuxAPI.get("/crm/user/checkUsername/" +  username)
        .then(response => {

            if (response.data.isUserExist == true) {
                dispatch(authFail("Your email is already registered, Please Login"));
            } else {
                agiliuxAPI.post("/crm/user/register", { username, firstname, lastname, email, password })
                    .then(response => {

                        console.log(response);
                        if (response.data['contacts'].length != 0) {
                            const user = JSON.stringify(response.data['contacts'][0]);
                            dispatch(authSuccess(response.data.accessToken, {}, "Successfully register. Please Login"));
                        } else {
                            dispatch(authFail("Ops. Registration fail!"));
                        }
                    })
                    .catch(err => {
                        dispatch(authFail("Ops. Something went wrong !"))
                    });
            }
        })
        .catch(err => {
            dispatch(authFail("Ops. Something went wrong !"))
        });
}

export const sendOTPemail = (username) => dispatch => {
    dispatch(authStart());
    agiliuxAPI.post("/crm/user/register", { username })
        .then(response => {
            if (response.data['contacts'].length != 0) {
                const user = JSON.stringify(response.data['contacts'][0]);
                dispatch(authOTP("OTP has been successfully sent to your Email. Please Login."));
            } else {
                dispatch(authFail("Ops. OTP fail!"));
            }
        })
        .catch(err => {
            dispatch(authFail("Ops. Something went wrong !"))
        });
}

export const logout = (accessToken) => {
        return dispatch => {
            localStorage.clear();
            dispatch(authLogout());
                // console.log("User action [ API called ]");
                // agiliuxAPI.post("user/logout", { token: accessToken })
                //     .then(response => {
                //             console.log("User action [ Success called ]");
                //             console.log(response);
                //             localStorage.clear();
                //             dispatch(authLogout());
                //     })
                //     .catch(err => {
                //             console.log("User action [ failed called ]");
                //             dispatch(authFail());
                //     });
        };
};

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if (!token) {
            dispatch(logout());
        } else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            if (expirationDate <= new Date()) {
                dispatch(logout());
            } else {
                const userId = localStorage.getItem('userId');
                dispatch(authSuccess(token, userId));
                dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000 ));
            }
        }
    };
};

export const startAction = (progressId) => ({
    type: 'START_ASYNC_ACTION', // your action name
    progressId,
});

export const stopAction = (progressId) => ({
    type: 'STOP_ASYNC_ACTION', // your action name
    progressId,
});

export const auth = (email, password) => {
    return dispatch => {
        dispatch(authStart());
        dispatch(startAction('-'));
        var authData = {
            username: email,
            password: password,
        };
        agiliuxAPI.post("/crm/user/login", authData)
            .then(response => {
                const data = response.data;
                const user = data['contacts'][0];

                if(user.forgetpassword_date == '' || user.forgetpassword_date == null){
                    const errorMessage = "Ops. OTP already expired !";
                    dispatch(authFail(errorMessage));
                }
                else
                {
                    let passtime = moment(user.forgetpassword_date).add(1, 'hours');
                    if(moment().isAfter(passtime)){
                        const errorMessage = "Ops. OTP already expired !";
                        dispatch(authFail(errorMessage));
                    }else{
                        localStorage.setItem("user", JSON.stringify(user));
                        localStorage.setItem('accessToken', data.accessToken);
                        localStorage.setItem('refreshTokens', data.refpaineshTokens);
                        dispatch(authSuccess(response.data.accessToken, user, response.message));
                    }
                }
                dispatch(stopAction("-"));
            })
            .catch(err => {
                const errorMessage = err.response ? err.response.data.message : "Ops. Something went wrong !";
                dispatch(authFail(errorMessage));
                dispatch(stopAction("-"));
            });
    };
};

export const setCompanyLogo = () => {
    return dispatch => {
        agiliuxAPI
            .post("crm/organization/detail")
            .then((response) => {
                console.log(response);
                // if (response[0].attachment["0"].fileurl) {
                //     dispatch(setLogo(response[0].attachment["0"].fileurl));
                // }
            })
    }
}

export const saveUserDetails = (user) => {
    return dispatch => {
        dispatch(authStart());
        dispatch(startAction('-'));
        const cleanUser = cleanUpObject(user);
        cleanUser.username = user.email;
        cleanUser.contactid = cleanUser.id;
        cleanUser.idno = cleanUser.id_card_no;

        if (user.birthday) {
            if (typeof user.birthday  === 'string' || user.birthday  instanceof String  ) {
                cleanUser.birthday = user.birthday;
            } else if ( user.birthday.isValid()) {
                cleanUser.birthday = user.birthday.format("DD-MM-YYYY");
            }
        }

        return new Promise((resolve, reject) => {
            agiliuxAPI.post("/crm/user/update", cleanUser)
                .then(response => {console.log('Response: ', response);
                    const user = response?.data?.contacts[0];
                    localStorage.setItem("user", JSON.stringify(user));
                    localStorage.setItem('accessToken', response?.data?.accessToken);
                    localStorage.setItem('refreshTokens', response?.data?.refreshTokens);
                    dispatch(authSuccess(response?.data?.accessToken, user, response.message));
                    dispatch(setUser(user));
                    dispatch(stopAction("-"));
                    resolve(response?.data);
                })
                .catch(err => {
                    console.log('SAVE USER ERR API: ', err);
                    dispatch(stopAction("-"));
                    reject(err);
                });
        });
    };
};
