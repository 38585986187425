import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';
import QuestionAnsForm from "../../components/InsuranceForm/BasicDetails/QuestionAnsForm";

const defaultState = {
    quotes : null,
    questionAns : {},
    worker : {},
    account : {},
};

const setQuotes = (state, action) => {
    return updateObject(state, {
        quotes: action.quotes
    })
};

const setWorker = (state, action) => {
    return updateObject(state, {
        worker: action.worker
    })
};

const setAccount = (state, action) => {
    return updateObject(state, {
        account: action.account
    })
};

const setQuestionAns = (state, action) => {
    return updateObject(state, {
        questionAns: action.questionAns
    })
};

const quotesReducer = (state = defaultState, action) => {
    switch(action.type){
        case actionTypes.SET_QUOTES: return setQuotes(state, action);
        case actionTypes.SET_WORKER: return setWorker(state, action);
        case actionTypes.SET_ACCOUNT: return setAccount(state, action);
        case actionTypes.SET_QUESTION_ANS: return setQuestionAns(state, action);
        default: return state
    }
};

export default quotesReducer
