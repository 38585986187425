import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';

const defaultState = {
    userEmail : "prakashbariya333@gmail.com",
    apiToken : "dZk_Qxvq3xmO1isUZXweby86Zl8pVqgo95LNFYK3yDQn3ib1DUSD8gcXuBrd_8SWlLM",
    auth_token : "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7InVzZXJfZW1haWwiOiJwcmFrYXNoYmFyaXlhMzMzQGdtYWlsLmNvbSIsImFwaV90b2tlbiI6ImRaa19ReHZxM3htTzFpc1VaWHdlYnk4NlpsOHBWcWdvOTVMTkZZSzN5RFFuM2liMURVU0Q4Z2NYdUJyZF84U1dsTE0ifSwiZXhwIjoxNjI0NDY1MDAzfQ.wdMsG_GtkXVvAiTZdVbd1B2lYxv51kqBXKzgpbPoA-w",
    countries : [],
    states : [],
    cities : []
};

const setCountries = (state, action) => {
    return updateObject(state, {
        countries: action.countries
    })
};

const setStates = (state, action) => {
    return updateObject(state, {
        states: action.states
    })
};

const setCities = (state, action) => {
    return updateObject(state, {
        cities: action.cities
    })
};

const universalReducer = (state = defaultState, action) => {
    switch(action.type){
        case actionTypes.SET_COUNTRIES : return setCountries(state, action);
        case actionTypes.SET_STATES: return setStates(state, action);
        case actionTypes.SET_CITIES: return setCities(state, action);
        default: return state
    }
};

export default universalReducer
