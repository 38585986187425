import React from 'react';
import { PersistGate } from 'redux-persist/integration/react'
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'antd/dist/antd.css';
import "bootstrap/dist/css/bootstrap.min.css";
import $ from 'jquery';
// import Popper from 'popper.js';
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./assets/css/custom_colors.css";
import "./assets/css/style.css";
import {Provider} from "react-redux";
import {persistor, reduxStore} from "./redux/configureStore";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { ThemeProvider, createTheme } from '@material-ui/core/styles';

require('dotenv').config();

const themeOptions = createTheme({
  palette: {
    primary: {
      main: '#292457',
    }
  },
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif'
    ]
  }
});

ReactDOM.render(
    <React.StrictMode>
      <ThemeProvider theme={themeOptions}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Provider store={reduxStore}>
              <PersistGate loading = {null} persistor = {persistor}>
                  <App/>
              </PersistGate>
          </Provider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
