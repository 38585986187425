import React, {Component} from "react";
import {withRouter} from 'react-router-dom';
import {Container, Modal} from "react-bootstrap";
import {connect} from "react-redux";
import {messagesCleanUp} from "../../../redux/actions/userActions";
import LoginForm from "./LoginForm";
import {LOGIN, SIGN_UP, OTP} from "./AuthModelType";
import RegisterForm from "./RegisterForm";
import OTPForm from "./OTPForm";

//Login Interface & Process
class AuthenticationModal extends Component {
  constructor() {
    super();
    this.state = {
      isAuthModal: false,
      _mode: OTP,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
      if (this.state.isAuthModal && this.props.isAuthenticated && this.props.isLastRequestSuccess ) {
          this.props.messagesCleanUp();
          this.setState({ isAuthModal: false });
          if (this.props.to) {
              this.props.history.push(this.props.to);
          } else if(this.props.onClick){
              this.props.onClick(this.props.user);
          }
      }
  }

    //Controls to show and hide login modal
  showAuthModal = () => {
        console.log("Authentication [ setStatus callld by showAuthModal ]");
        this.setState({ isAuthModal: true });
  };

  closeModal = () => {
        this.setState({ isAuthModal: false, message: "", _status: "" })
        this.props.messagesCleanUp();
  };

  modeChangedHandler = (mode) => {
      this.setState({ _mode: mode, });
      this.props.messagesCleanUp();
  }

  _message = (e) => {
    if (this.props.message !=  null) {
      const color = this.props.isLastRequestSuccess ? "success" : "danger";
      return (
          <>
            <Modal.Footer style={{ backgroundColor: color }}>
              <Container className={`alert  alert-${color}`}>
                <p className="my-0">{this.props.message}</p>
              </Container>
            </Modal.Footer>
          </>
      );
    }
  };

  render() {
    return ( !this.props.isAuthenticated ?
      <>
        { this.props.children?
            React.cloneElement( this.props.children, { onClick: this.showAuthModal } ) :
            <li className="nav-item"  style = {{listStyleType: 'none'}}>
                <a className="nav-link btn btn-primary text-white sign_in" onClick={this.showAuthModal}>
                    Log In / Sign Up
                </a>
            </li>
        }

        <Modal show={this.state.isAuthModal} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state._mode === SIGN_UP ? "Sign Up" : "Log In"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state._mode === LOGIN
              ? <LoginForm modelChanged = {this.modeChangedHandler}/>
              : this.state._mode === SIGN_UP
                ? <RegisterForm modelChanged = {this.modeChangedHandler}/>
                : this.state._mode === OTP
                  ? <OTPForm modelChanged = {this.modeChangedHandler}/>
                  : this._bodyForgotPassword()}
          </Modal.Body>
          {this._message()}
        </Modal>
      </> : (this.props.children ? React.cloneElement( this.props.children) : null )
    );
  }
}

//Redux
const mapStateToProps = (state) => {
  return {
        message : state.userReducer.message,
        user : state.userReducer.user,
        isLastRequestSuccess:  state.userReducer.isLastRequestSuccess,
        isAuthenticated:  state.userReducer.accessToken != null,
        authRedirectPath: state.userReducer.authRedirectPath ,
        loading: state.userReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
        messagesCleanUp: () => dispatch(messagesCleanUp()),
  };
};

export default withRouter( connect(mapStateToProps, mapDispatchToProps)(AuthenticationModal));
