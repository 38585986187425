import agiliuxAPI from "../../services/agiliuxAPI";
import * as actionTypes from './actionTypes';
import {authStart, setUser, startAction, stopAction} from "./userActions";
import {updateObject} from "../../shared/utility";
import moment from 'moment'

export const setProductList = (productList) => ({type: actionTypes.SET_PRODUCT_LIST, productList: productList})

export const addProductList = (productList) => ({type: actionTypes.ADD_PRODUCT_LIST, productList: productList})

export const updateProductList = (productList) => ({type: actionTypes.UPDATE_PRODUCT_LIST, productList: productList})

export const setSelectedProduct = (selectedProduct) => ({
  type: actionTypes.SET_SELECTED_PRODUCT,
  selectedProduct: selectedProduct
})

export const setEtiqaAppRef = applicationReference => ({
  type: actionTypes.ETIQA_APPLICATION_REF,
  applicationReference
})

export const setMSIGAppRef = applicationReference => ({
  type: actionTypes.MSIG_APPLICATION_REF,
  applicationReference
})

export const setFaqProduct = (faqProduct) => ({type: actionTypes.SET_FAQ_PRODUCT, faqProduct: faqProduct})

export const setBasicInfo = (basicInfo) => ({type: actionTypes.SET_BASIC_COVERAGE_INFO, basicInfo: basicInfo})

export const setOptionalCovers = (optionalCovers) => ({
  type: actionTypes.SET_OPTIONAL_COVERS,
  optionalCovers: optionalCovers
})

export const resetOptionalCovers = (optionalCovers) => ({
  type: actionTypes.RESET_OPTIONAL_COVERS,
  optionalCovers: optionalCovers
});

export const setOptionalPricing = (optionalPricing) => ({
  type: actionTypes.SET_OPTIONAL_PRICING,
  optionalPricing: optionalPricing
})

export const setSearchFilter = (searchFilter) => {
  return {type: actionTypes.SET_SEARCH_FILTER, searchFilter: searchFilter};
}

export const clearUpSearchFilter = () => {
  return {type: actionTypes.CLEAR_UP_SEARCH_FILTER};
}

export const requestProductList = () => {
  return dispatch => {
    agiliuxAPI.get("crm/product/list").then((res) => {
      const productData = res.products;

      for (let x = 0; x < productData.length; x++) {
        var desc = productData[x].description;
        var splittedDesc = desc.replace("<p>", "");
        splittedDesc = splittedDesc.replace("</p>", "");
        splittedDesc = splittedDesc.split("*");

        if (productData[x].pricing === undefined) {
          productData[x].pricing = {
            premium: "0"
          };
        }

        var itemData = [];
        if (splittedDesc.length === 1) {
          itemData.push(splittedDesc[0])
        } else {
          for (let i = 0; i < splittedDesc.length; i++) {
            if (i == 0) {
              continue;
            } else {
              itemData.push(splittedDesc[i])
            }
          }
        }
        productData[x].itemData = itemData;
      }

      dispatch(setProductList(productData));
    });
  };
};

export const getProductDetails = (itemid) => dispatch => {
  return agiliuxAPI.post("crm/product/detail", {id: itemid});
}

export const requestSelectedProduct = (itemid) => {
  return dispatch => {
    agiliuxAPI.post("crm/product/detail", {id: itemid}).then((res) => {
      dispatch(setSelectedProduct(res.data));
    }).catch(err => {
      console.log("User action [ failed called ]");
    });

  };
};

export const requestMaidProductList = (searchFilter) => {
  return dispatch => {
    const searchFilterRequest = {
      startdate: searchFilter?.date?.value.format("DD-MM-YYYY"),
      month: searchFilter?.month?.value,
      country: searchFilter?.country?.value,
      promoCode: searchFilter?.promo?.value || searchFilter?.promoCode?.value,
      insurer_name: searchFilter?.insurer_name?.value,
      status_workers: searchFilter?.status_workers?.value,
    }
    agiliuxAPI.post("crm/product/maid", searchFilterRequest).then((res) => {
      if (res.maidpricing) {
        Object.entries(res.maidpricing).forEach(([key, value]) => {
          if(value.productname == 'QBE Maid Insurance'){
            const qbeProductInfo = res.maidpricing[key];

            qbeProductInfo?.pricing?.map(pricingUnit => {
              return Object.assign(pricingUnit, {productInfo: qbeProductInfo});
            })

            if(key == 0){
              dispatch(setProductList(qbeProductInfo.pricing));
            }else{
              dispatch(addProductList(qbeProductInfo.pricing));
            }
          }
          else if(value.productname == 'MSIG Maid Insurance'){
            let msigProducts = res.maidpricing[key];
            msigProducts.pricing.map(pricingUnit => {
              return Object.assign(pricingUnit, {productInfo: msigProducts});
            })

            if(key == 0){
              dispatch(setProductList(msigProducts.pricing));
            }else{
              dispatch(addProductList(msigProducts.pricing));
            }

            dispatch(requestMSIGProductList(searchFilterRequest, msigProducts.pricing));
          } else if (value.productname === 'Etiqa Maid Insurance') {
            let etiqaProducts = res.maidpricing[key];
            etiqaProducts.pricing.map(pricingUnit => {
              return Object.assign(pricingUnit, { productInfo: etiqaProducts?.pricing });
            });

            if (key == 0) { dispatch(setProductList(etiqaProducts?.pricing)); }
            else { dispatch(addProductList(etiqaProducts?.pricing)); }

            dispatch(requestEtiqaProductList(searchFilterRequest, etiqaProducts?.pricing));
          }
        });
      } else {
        dispatch(setProductList([]));
      }

    }).catch(err => {
      dispatch(setProductList([]));
    });

  };
};

export const requestMSIGProductList = (searchFilter, msigProducts) => {
  return dispatch => {
    const searchFilterRequest = {
      productcode: "maid",
      ...searchFilter,
    }

    agiliuxAPI.post("msig/quotation", searchFilterRequest)
      .then((res) => {
        if (res.data) {
          msigProducts.forEach((part, index, theArray) => {
            let premiumProduct = res.data.premium.filter(product => theArray[index]['plancode'] === product['planCode']);
            theArray[index]['premium'] = premiumProduct[0]['basicPremium'];
            theArray[index]['productCode'] = premiumProduct[0]['productCode'];
            theArray[index]['quoteReference'] = res.data.quoteReference;
            theArray[index]['validThrough'] = res.data.validThrough;

          });
          console.log(msigProducts, "ayush")
          dispatch(updateProductList(msigProducts));
        }
      }).catch(err => {
      console.log("User action [ failed called ]");
    });

  };
};

export const requestEtiqaProductList = (searchFilter, etiqaProducts) => {
  return dispatch => {
    const searchFilterReq = {
      productCode: "maid",
      ...searchFilter,
    };

    agiliuxAPI.post('etiqa/quotation', searchFilterReq)
      .then(res => {
        if (res?.data) {
          etiqaProducts?.forEach((part, index, theArray) => {
            let premiumProduct = res?.data?.premium?.filter(product => theArray[index]?.plancode == product?.planCode);

            if (premiumProduct?.length) {
              theArray[index]['premium'] = premiumProduct[0]['basicPremium'];
              theArray[index]['productCode'] = premiumProduct[0]['productcode'] || "Etiqa";
              theArray[index]['discountType'] = premiumProduct[0]['discountType'];
              theArray[index]['discountAmount'] = premiumProduct[0]['discountAmount'];
              theArray[index]['discountValue'] = premiumProduct[0]['discountValue'];
              theArray[index]['premium_after_dis'] = premiumProduct[0]['premium_after_dis'];
              theArray[index]['quoteReference'] = res?.data?.quoteReference;
              theArray[index]['validThrough'] = res?.data?.validThrough;
            }
          }); // end for-each loop
          dispatch(updateProductList(etiqaProducts));
        }
      })
      .catch(err => console.log("User action Etiqa Quotation [ failed called ] ", err));
  };
};

export const requestCalculateMSIGPricing = (searchFilter) => {
  return dispatch => {
    const searchFilterRequest = updateObject(searchFilter, {productcode: "maid"})

    if (!searchFilterRequest.status_workers)
      searchFilterRequest.status_workers = searchFilter?.maidPermitType;

    dispatch(setOptionalCovers({ quoteReference : null }));
    return new Promise((resolve, reject) => {
      agiliuxAPI.post("msig/quotation", searchFilterRequest)
        .then((res) => {
          if (res.pricing) {
            const searchFilterQuoteReference = updateObject(searchFilterRequest,
              {quoteReference: res.pricing.quoteReference});
            dispatch(setOptionalCovers(searchFilterQuoteReference));
            dispatch(setOptionalPricing(res.pricing));
            resolve(res.data);
          }
        }).catch(err => {
        reject(err);
        console.log("User action [ failed called ]");
      });
    });

  };
};

export const requestCalculateEtiqaPricing = searchFilter => {
  return dispatch => {
    if (searchFilter?.promo) {
      searchFilter.promoCode = searchFilter.promo;
      delete searchFilter.promo;
    }

    if (searchFilter?.date) delete searchFilter.date;

    const searchFilterRequest = updateObject(searchFilter, {productcode: "maid", month: `${searchFilter?.month} Month`});

    if (!searchFilterRequest.country || searchFilterRequest.month.includes('undefined')) { return; }

    return new Promise((resolve, reject) => {
      agiliuxAPI.post("etiqa/quotation", searchFilterRequest)
        .then(res => {
          dispatch(setOptionalPricing(res?.pricing));
          resolve(res?.data);
        })
        .catch(err => {
          console.log("User action Etiqa pricing [ failed called ] ", err);
          reject(err);
        });
    });
  };
};

export const createMSIGApplication = (optionalCovers, user, worker) => {
  return dispatch => {

    const userRequest = updateObject(user, {
      salutation: user.salutationtype
    })

    if (user.birthday_temp) {
      if (typeof user.birthday_temp  === 'string' || user.birthday_temp  instanceof String  ) {
        userRequest.birthday = moment(user.birthday_temp, 'YYYY-MM-DD').format('DD-MM-YYYY');
      } else if ( user.birthday_temp.isValid()) {
        userRequest.birthday = user.birthday_temp.format("DD-MM-YYYY");
      }
    }
    else if (user.birthday) {
      if (typeof user.birthday  === 'string' || user.birthday  instanceof String  ) {
        userRequest.birthday = moment(user.birthday, 'YYYY-MM-DD').format('DD-MM-YYYY');
      } else if ( user.birthday.isValid()) {
        userRequest.birthday = user.birthday.format("DD-MM-YYYY");
      }
    }
    else{
      userRequest.birthday = moment().subtract(21, "year").format('DD-MM-YYYY');
    }

    const workerRequest = updateObject(worker, {
      date_birth: worker.date_birth ? worker.date_birth.format("DD-MM-YYYY") : moment().subtract(21, "year").format('DD-MM-YYYY'),
      salutation: worker.worker_salutationtype
    })

    if (optionalCovers?.month?.toString()?.indexOf('Month') < 0 || typeof optionalCovers === 'number') {
      optionalCovers.month = optionalCovers.month + ' Month';
    }

    const optionalCoversRequest = updateObject(optionalCovers, {user: userRequest, worker: workerRequest})

    return new Promise((resolve, reject) => {
      agiliuxAPI.post("msig/application", optionalCoversRequest)
        .then((res) => {
          if (res.data) {
            dispatch(setOptionalCovers(res.data));
            resolve(res.data);
          }

        }, err => {
          console.log("User action [ failed called ]");
          reject(err);
        });
    });
  };
};

export const createMSIGPollicy = (optionalCovers, user, worker) => {
  return dispatch => {

    const userRequest = updateObject(user, {
      salutation: user.salutationtype
    })

    if (user.birthday_temp) {
      if (typeof user.birthday_temp  === 'string' || user.birthday_temp  instanceof String  ) {
        userRequest.birthday = moment(user.birthday_temp, 'YYYY-MM-DD').format('DD-MM-YYYY');
      } else if ( user.birthday_temp.isValid()) {
        userRequest.birthday = user.birthday_temp.format("DD-MM-YYYY");
      }
    }
    else if (user.birthday) {
      if (typeof user.birthday  === 'string' || user.birthday  instanceof String  ) {
        userRequest.birthday = moment(user.birthday, 'YYYY-MM-DD').format('DD-MM-YYYY');
      } else if ( user.birthday.isValid()) {
        userRequest.birthday = user.birthday.format("DD-MM-YYYY");
      }
    }
    else{
      userRequest.birthday = moment().subtract(21, "year").format('DD-MM-YYYY');
    }

    const workerRequest = updateObject(worker, {
      salutation: worker.worker_salutationtype
    })

    if (worker.date_birth ) {
      if (moment.isMoment(worker.date_birth)) {
        workerRequest.date_birth = worker.date_birth.format("DD-MM-YYYY");
      } else {
        workerRequest.date_birth = moment(worker.date_birth).format("DD-MM-YYYY");
      }
    }
    else{
      workerRequest.date_birth = moment().subtract(21, "year").format('DD-MM-YYYY');
    }

    const optionalCoversRequest = updateObject(optionalCovers, {user: userRequest, worker: workerRequest})

    return new Promise((resolve, reject) => {
      agiliuxAPI.post("msig/policies", optionalCoversRequest)
        .then((res) => {
          if (res.data) {
            dispatch(setOptionalCovers(res.data));
            resolve(res.data);
          }

        }, err => {
          console.log("User action [ failed called ]");
          reject(err);
        });
    });
  };
};

export const createEtiqaApplication = (optionalCovers, user, worker) => {
  return dispatch => {
    const userRequest = updateObject(user, {});
    if (user.birthday_temp) {
      if (typeof user.birthday_temp  === 'string' || user.birthday_temp  instanceof String  ) {
        userRequest.birthday = moment(user.birthday_temp, 'YYYY-MM-DD').format('DD-MM-YYYY');
      } else if ( user.birthday_temp.isValid()) {
        userRequest.birthday = user.birthday_temp.format("DD-MM-YYYY");
      }
    }
    else if (user.birthday) {
      if (typeof user.birthday  === 'string' || user.birthday  instanceof String  ) {
        userRequest.birthday = moment(user.birthday, 'YYYY-MM-DD').format('DD-MM-YYYY');
      } else if ( user.birthday.isValid()) {
        userRequest.birthday = user.birthday.format("DD-MM-YYYY");
      }
    }
    else{
      userRequest.birthday = moment().subtract(21, "year").format('DD-MM-YYYY');
    }

    const workerRequest = updateObject(worker, {});
    if (worker.date_birth ) {
      if (moment.isMoment(worker.date_birth)) {
        workerRequest.date_birth = worker.date_birth.format("DD-MM-YYYY");
      } else {
        workerRequest.date_birth = moment(worker.date_birth).format("DD-MM-YYYY");
      }
    }
    else{
      workerRequest.date_birth = moment().subtract(21, "year").format('DD-MM-YYYY');
    }

    const optionalCoversRequest = updateObject(optionalCovers, {user: userRequest, worker: workerRequest});

    optionalCoversRequest.productcode = 'maid';
    if (optionalCoversRequest?.care_allowance) { delete optionalCoversRequest?.care_allowance }
    if (optionalCoversRequest?.daily_hospitalcash) { delete optionalCoversRequest?.daily_hospitalcash }
    if (optionalCoversRequest?.indemnity_buyback) { delete optionalCoversRequest?.indemnity_buyback }
    if (optionalCoversRequest?.hasOwnProperty('insurer_name')) { delete optionalCoversRequest?.insurer_name }
    if (optionalCoversRequest?.premium) { delete optionalCoversRequest.premium }
    if (optionalCoversRequest?.month) { optionalCoversRequest.month = `${optionalCoversRequest.month} Month` }
    if (optionalCoversRequest?.promo) { delete optionalCoversRequest.promo }
    if (optionalCoversRequest?.date) { delete optionalCoversRequest.date }
    if (userRequest?.birthday_temp) { delete userRequest.birthday_temp }

    optionalCoversRequest.return_url = process.env.REACT_APP_PORTAL_URL;

    return new Promise((resolve, reject) => {
      agiliuxAPI.post('etiqa/application', optionalCoversRequest)
        .then(res => {
          dispatch(setEtiqaAppRef(res?.data?.applicationReference));
          window.localStorage.setItem('etiqaAppRef', res?.data?.applicationReference);
          resolve(res?.data);
        })
        .catch(err => {
          console.log("User action Etiqa Application [ failed called ]: ", err);
          reject(err);
        });
    });
  };
};

export const createEtiqaPolicy = (applicationReference) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      agiliuxAPI.post('etiqa/policies', { applicationReference })
        .then(res => {
          resolve(res?.data);
        })
        .catch(err => {
          console.log("User action Etiqa Policy [ failed called ]: ", err);
          reject(err);
        });
    });
  };
};

export const requestFaqProduct = (itemid) => {
  return dispatch => {
    agiliuxAPI.post("/crm/faq/list", {productid: itemid}).then((res) => {
      var tempData = []
      for (let x = 0; x < res.list.length; x++) {
        var data = {
          question: res.list[x].question,
          answer: res.list[x].faq_answer
        }
        tempData.push(data);
      }
      dispatch(setFaqProduct(tempData));
    }).catch(err => {
      console.log("User action [ failed called ]");
    });

  };
};

export const requestOptionalPricing = (optionalCovers) => {
  return dispatch => {
    dispatch(authStart());
    dispatch(startAction('-'));
    agiliuxAPI.post("crm/product/calculatePricing", optionalCovers)
      .then(res => {
        if (res.pricing) {
          dispatch(setOptionalPricing(res.pricing));
          dispatch(stopAction("-"));
        }
      })
      .catch(err => {
        dispatch(stopAction("-"));
      });
  };
};

