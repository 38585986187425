import React, {Component} from "react";
import {Element, scroller} from 'react-scroll'
import HomeLayout from "../../layouts/Home/HomeLayout";
import Howitworks from "./howitworks/Howitworks";
import Products from "./products/Products";
import Partners from "./partners/Partners";
import Claims from "./claims/Claims"
import Customers from "./customers/Customers"
import About from "./about/About"
import Whyus from "./whyus/Whyus"
import Contactus from "./contactus/Contactus"
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

class Home extends Component {

    componentDidMount() {
        if (this.props.currentActiveTab)  {
            scroller.scrollTo(this.props.currentActiveTab);
        }
    }

    render() {
        return (
            <HomeLayout>
                <Element name="about" className="home home_main">
                    <About/>
                </Element>

                <Element name="ourpartners" className="home home_ourpartners">
                    <Partners/>
                </Element>
                
                <Element name="howitworks" className="home home_howitworks">
                    <Howitworks/>
                </Element>

                {/* <Element name="products" className="home home_ourproducts">
                    <Products/>
                </Element> */}


                {/* <Element name="whyus" className="home home_whyus">
                    <Whyus/>
                </Element> */}

                {/* <Element name="claims" className="home home_claims">
                    <Claims/>
                </Element> */}

                {/* <Element name="ourcustomers" className="home home_ourcustomers">
                    <Customers/>
                </Element> */}

                {/*<Element name="faq" className="home home_faq" >*/}
                {/*  <FAQ faqData={[{*/}
                {/*    question: "Can i cancel the booking?",*/}
                {/*    answer: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>"*/}
                {/*  }, {*/}
                {/*    question: "How to change the insurance provider?",*/}
                {/*    answer: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>"*/}
                {/*  }]}></FAQ>*/}
                {/*</Element>*/}

                <Element name="contact Us" className="home home_contactform">
                    <Contactus/>
                </Element>

            </HomeLayout>);
    }
}

const mapStateToProps = (state) => {
    return {
        currentActiveTab: state.userReducer.currentActiveTab,
    };
};

export default withRouter( connect(mapStateToProps)(Home));

