import React, { Component, useState } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Card,
  Form,
  FormGroup,
} from "react-bootstrap";
import moment from "moment";
import Footer from "../../Footer/Footer";
import { DatePicker, Space } from "antd";
import { Link } from "react-router-dom";
import msigIcon from "../../../assets/images/msig.jpg";
import etiqaIcon from "../../../assets/images/eTiQa.jpg";
import qbeIcon from "../../../assets/images/qbe.jpg";
import Slider from "react-slick";
import { checkValidity, updateObject } from "../../../shared/utility";
import { fetchUser, logout } from "../../../redux/actions";
import {
  setCompanyLogo,
  setCurrentActiveTab,
} from "../../../redux/actions/userActions";
import { connect } from "react-redux";
import { setSearchFilter } from "../../../redux/actions/productActions";
import productReducer from "../../../redux/reducers/productReducer";

const topWrap = {
  backgroundColor: "#F6F6F6",
  paddingTop: " 160px",
  // paddingBottom: "40px"
};
const footer = {
  marginTop: "40px",
};
const sliderImage = {
    width: 95,
    background: "white",
    padding: 5,
    textAlign: "center",
    borderRadius: 30,
    boxShadow: "rgb(0 0 0 / 15%) 0px 5px 15px",
    transition: "transform .5s ease-in-out , box-shadow 0.5s ease-in-out",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "3rem",
};
const totalPadding = {
  padding: "15px 0px 0px 0px",
  borderRadius: "12px",
};

class MaidInsuranceSearch extends Component {
  state = {
    insurerDisable: null,
    promoDisable: null,
    formIsValid: this.props.searchFilter.formIsValid,
    searchFilter: {
      date: {
        value: this.props.searchFilter.date
          ? moment(this.props.searchFilter.date, "YYYY-MM-DD")
          : "",
        validation: {
          error: "Date is required",
          required: true,
          isDate: true,
        },
        valid: false,
      },
      month: {
        value: this.props.searchFilter.month,
        validation: {
          error: "Month is required",
          required: true,
        },
      },
      country: {
        value: this.props.searchFilter.country,
        validation: {
          error: "Country is required",
          required: true,
        },
        valid: false,
      },
      promo: {
        value: this.props.searchFilter.promo_selection ? this.props.searchFilter.promo_selection : this.props.searchFilter.promo,
        valid: true,
      },
      insurer_name: {
        value: this.props.searchFilter.insurer_selection ? this.props.searchFilter.insurer_selection : this.props.searchFilter.insurer_name,
        valid: true,
      },
      promo_selection:{
        value: this.props.searchFilter.promo_selection,
      },
      insurer_selection:{
        value: this.props.searchFilter.insurer_selection,
      },
    },
  };

  disabledDate = (current) => {
    return current && current < moment().endOf("day").add(7, "days");
  };
  dateChangedHandler = (moment, dateString) => {
    const controlName = "date";
    const updatedControls = updateObject(this.state.searchFilter, {
      [controlName]: updateObject(this.state.searchFilter[controlName], {
        value: moment,
        touched: true,
        valid: moment && moment.isValid(),
      }),
    });

    this.isInvalidSearchFilter(updatedControls);
  };

  inputChangedHandler = (event) => {
    const controlName = event.target.name;
    const updatedControls = updateObject(this.state.searchFilter, {
      [controlName]: updateObject(this.state.searchFilter[controlName], {
        value: event.target.value,
        touched: true,
        valid: checkValidity(
          event.target.value,
          this.state.searchFilter[controlName].validation
        ),
      }),
    });

    this.isInvalidSearchFilter(updatedControls);
  };

  isInvalidSearchFilter = (updatedControls) => {
    let searchFilter = {};
    let isValid = true;
    for (let field in updatedControls) {
      isValid = isValid && updatedControls[field].valid;
      searchFilter[field] = updatedControls[field].value;
    }
    searchFilter["formIsValid"] = isValid;
    this.setState({
      searchFilter: updatedControls,
      formIsValid: isValid,
    });
    this.props.setSearchFilter(searchFilter);
  };

  formDetailsValidation = (touched) => {
    let formIsValid = true;
    let searchFilterForm = { ...this.state.searchFilter };
    Object.keys(this.state.searchFilter).map((controlName) => {
      let isValid = checkValidity(
        this.state.searchFilter[controlName].value,
        this.state.searchFilter[controlName].validation
      );
      formIsValid = formIsValid && isValid;
      searchFilterForm[controlName] = updateObject(
        this.state.searchFilter[controlName],
        {
          touched: touched,
          valid: isValid,
        }
      );
    });
    this.isInvalidSearchFilter(searchFilterForm);
  };

  componentDidMount() {
    this.formDetailsValidation(false);
    if(this.props.searchFilter.insurer_selection && this.props.searchFilter.insurer_selection != ''){
      this.setState({insurerDisable: 'true'});
    }else{
      this.setState({insurerDisable: null});
    }

    if(this.props.searchFilter.promo_selection && this.props.searchFilter.promo_selection != ''){
      this.setState({promoDisable: 'true'});
    }else{
      this.setState({promoDisable: null});
    }
  }

  isValid = (form) => {
    if (form.touched) {
      return form.validation && form.valid;
    } else {
      return true;
    }
  };
  render() {
    let defaultPickerValue = moment().add(7, "d");
    return (
      <Row>
        <Col lg="4" className="mb-4">
          <Card className="sliderImage detailview">
            <Card.Body>
              <Card.Title>
                <b>Get the best Insurance</b>
              </Card.Title>
              <Row className={"mt-4 sidebar-slider"}>
                <div lg={4} md={4}>
                  <img style={sliderImage} src={msigIcon} alt="" className="img-size" />
                </div>
                <div lg={4} md={4}>
                  <img style={sliderImage} src={etiqaIcon} alt="" className="img-size" />
                </div>
                <div lg={4} md={4}> 
                  <img style={sliderImage} src={qbeIcon} alt="" className="img-size"/>
                </div>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={8}>
          <Card>
            <Card.Body >
              <Card.Title>
                <b>Coverage date and details</b>
              </Card.Title>
              <Form className={"mt-4"}>
                <Row className="mb-3">
                  <Col lg={12}>
                    <label htmlFor="policy">Policy Starting From</label>
                    <DatePicker
                      name="date"
                      defaultPickerValue={defaultPickerValue}
                      defaultValue={this.state.searchFilter.date.value}
                      disabledDate={this.disabledDate}
                      className="form-control"
                      placeholder="Select Date"
                      onChange={this.dateChangedHandler}
                      format="DD-MM-YYYY"
                      isInvalid={!this.isValid(this.state.searchFilter.date)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.searchFilter.date.validation.error}
                    </Form.Control.Feedback>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col lg={12}>
                    <label htmlFor="policy">For</label>
                    <Form.Control
                      as="select"
                      name="month"
                      id="month"
                      value={this.state.searchFilter.month.value}
                      className="form-control"
                      onChange={this.inputChangedHandler}
                      isInvalid={!this.isValid(this.state.searchFilter.month)}
                    >
                      <option value="">Select Month</option>
                      <option value="14 Month">14 Month</option>
                      <option value="26 Month">26 Month</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {this.state.searchFilter.month.validation.error}
                    </Form.Control.Feedback>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col lg={12}>
                    <label htmlFor="policy">My Maid is From</label>
                    <Form.Control
                      as="select"
                      name="country"
                      id="country"
                      value={this.state.searchFilter.country.value}
                      className="form-control"
                      onChange={this.inputChangedHandler}
                      isInvalid={!this.isValid(this.state.searchFilter.country)}
                    >
                      <option value="">Select Country</option>
                      <option value="Philippines">Philippines</option>
                      <option value="Indonesia">Indonesia</option>
                      <option value="Myanmar">Myanmar</option>
                      <option value="Cambodia">Cambodia</option>
                      <option value="Bangladesh">Bangladesh</option>
                      <option value="Hong Kong">Hong Kong</option>
                      <option value="India">India</option>
                      <option value="Malaysia">Malaysia</option>
                      <option value="South Korea">South Korea</option>
                      <option value="Sri Lanka">Sri Lanka</option>
                      <option value="Taiwan">Taiwan</option>
                      <option value="Thailand">Thailand</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {this.state.searchFilter.country.validation.error}
                    </Form.Control.Feedback>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col lg={12}>
                    <label htmlFor="insurance">Get best Insurance</label>
                    <Form.Control
                      as="select"
                      name="insurer_name"
                      id="insurer_name"
                      value={this.state.searchFilter.insurer_name.value}
                      className="form-control"
                      onChange={this.inputChangedHandler}
                      disabled={this.state.insurerDisable}
                    >
                      <option value="">Select Insurance</option>
                      <option value="qbe">QBE</option>
                      {/* <option value="msig">MGIG</option>
                      <option value="etiqa">ETIQA</option> */}
                    </Form.Control>
                  </Col>
                </Row>

                <Row className="mb-4 pt-2">
                  <Col lg={12}>
                    <label htmlFor="promo">Promo Code (if Applicable)</label>
                    <Form.Control
                      className="fdd"
                      value={this.state.searchFilter.promo.value}
                      name="promo"
                      type="text"
                      id="promo"
                      placeholder="Input Code"
                      onChange={this.inputChangedHandler}
                      disabled={this.state.promoDisable}
                    />
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    searchFilter: state.productReducer.searchFilter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSearchFilter: (searchFilter) => dispatch(setSearchFilter(searchFilter)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MaidInsuranceSearch);
