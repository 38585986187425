import {applyMiddleware, compose, createStore} from "redux";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import {createLogger} from "redux-logger";
import reduxThunk from "redux-thunk";
import rootReducer from "./reducers";


// const persistedReducer = persistReducer(persistConfig, rootReducer);


// const reduxLogger = createLogger({
//     collapsed: true,
//     predicate: () => process.env.NODE_ENV === "development"
// });

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const reduxMiddleware = applyMiddleware(reduxThunk);
const reduxStore = createStore(rootReducer, composeEnhancers(reduxMiddleware));
const persistor = persistStore(reduxStore);

// Enable Webpack hot module replacement for reducers
if (module.hot) {
    module.hot.accept("./reducers", () => {
        const nextRootReducer = require("./reducers").default;
        reduxStore.replaceReducer(nextRootReducer);
    });
}

export  {persistor, reduxStore};

