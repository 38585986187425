import axios from 'axios';

const universalAPI = axios.create({
   baseURL: `https://www.universal-tutorial.com/api/`
});

universalAPI.interceptors.request.use(function (config) {
   config.headers.Authorization =  'Bearer ' +
       'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7InVzZXJfZW1haWwiOiJwcmFrYXNoYmFyaXlhMzMzQGdtYWlsLmNvbSIsImFwaV90b2tlbiI6ImRaa19ReHZxM3htTzFpc1VaWHdlYnk4NlpsOHBWcWdvOTVMTkZZSzN5RFFuM2liMURVU0Q4Z2NYdUJyZF84U1dsTE0ifSwiZXhwIjoxNjI0NDY1MDAzfQ.wdMsG_GtkXVvAiTZdVbd1B2lYxv51kqBXKzgpbPoA-w';
   return config;
});

export default universalAPI;
