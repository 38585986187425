import React from "react";
import "./LogoImage.css";

const LogoImage = (props) => {
    const [blur, setBlur] = React.useState(true);
    const loadingImage = React.useRef();

    React.useEffect(() => {
        if (loadingImage.current.complete) {
            setBlur(false);
        }

        loadingImage.current.addEventListener('load', () => {
            setBlur(false);
        });
    }, []);

    return (
        <div className={`image-container ${blur ? "blur" : "unblur"}`}>
            <img className="placeholder-image" src={props.smallImgSrc}/>
            <img className="real-image" ref={loadingImage} src={props.largeImgSrc} />
        </div>
    )
}

export default LogoImage;
