import React, {Component} from "react";
import Footer from "../../components/Footer/Footer";


//Basic layout with footer
class HomeLayout extends Component {

  render() {
    return (
      <>

          {this.props.children}
        <Footer></Footer>
      </>
    );
  }
}

export default HomeLayout;
