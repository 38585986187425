import universalAPI from "../../services/universalAPI";
import * as actionTypes from './actionTypes';
import {authFail, authStart, authSuccess, startAction, stopAction} from "./userActions";
import {setProductList} from "./productActions";

export const setCountries = (countries) => ({ type: actionTypes.SET_COUNTRIES, countries : countries })

export const setStates = (states) => ({ type: actionTypes.SET_STATES, states : states })

export const setCities = (cities) => ({ type: actionTypes.SET_CITIES, cities : cities })


export const requestCountries = () => {
    return dispatch => {
        universalAPI.get("/countries").then(response => {
            dispatch(setCountries(response.data));
        }).catch(err => {
            dispatch(setCountries([]));
        });
    };
};

export const requestStatesByCountry = (country) => {
    return dispatch => {
        universalAPI.get("/states/" + country).then(response => {
            dispatch(setStates(response.data));
        }).catch(err => {
            dispatch(setStates([]));
        });
    };
};


export const requestCitiesByState = (state) => {
    return dispatch => {
        universalAPI.get("/cities/" + state).then(response => {
            dispatch(setCities(response.data));
        }).catch(err => {
            dispatch(setCities([]));
        });
    };
};
