import React, {Component} from "react";
import {connect} from "react-redux";
import {sendOTPemail, messagesCleanUp} from "../../../redux/actions/userActions";
import {Col, Form, InputGroup, Row} from "react-bootstrap";
import {LOGIN} from "./AuthModelType";
import {checkValidity, updateObject} from "../../../shared/utility";

class OTPForm extends Component {

    state = {
        formIsValid: false,
        isExist: true,
        registerForm: {
            email: {
                value: '',
                validation: {
                    error : "Enter valid email address",
                    required: true,
                    isEmail: true
                },
                valid: false,
                touched: false
            }
        }
    };

    sendOTP = (e) => {
        e.preventDefault();
        this.props.sendOTPemail(this.state.registerForm.email.value);
        this.props.modelChanged(LOGIN);
    };

    inputChangedHandler = ( event ) => {
        const controlName = event.target.name;
        const updatedControls = updateObject( this.state.registerForm, {
            [controlName]: updateObject( this.state.registerForm[controlName], {
                value: event.target.value,
                valid: checkValidity( event.target.value, this.state.registerForm[controlName].validation ),
                touched: true,
            } )
        } );
        this.setState( {
            registerForm: updatedControls,
            formIsValid : updatedControls.email.valid
        } );
        this.props.messagesCleanUp();
    }

    isValid = (form) => {
        return !form.touched || (form.validation &&  form.valid);
    }

    render() {
        return (
            <Form role="form" id="userRegister" onSubmit={this.sendOTP}>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <InputGroup hasValidation>
                        <Form.Control
                            type="email"
                            name="email"
                            onChange={this.inputChangedHandler}
                            placeholder="Enter email"
                            isInvalid={ !this.isValid(this.state.registerForm.email)}/>
                        <Form.Control.Feedback type="invalid">
                            { !this.isValid(this.state.registerForm.email) ?
                                this.state.registerForm.email.validation.error : null
                            }
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
                <button className="loadingButton btn btn-primary" type="submit"
                        disabled={this.props.loading || !this.state.formIsValid}>
                    {this.props.loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : null}
                    Get OTP
                </button>
                <Row className="mt-3">
                    <Col xs="6"></Col>
                    <Col className="text-right" xs="6">
                        <a href="javascript:void(0)" onClick={() => this.props.modelChanged(LOGIN)}>
                            <small>Back to Login</small>
                        </a>
                    </Col>
                </Row>
            </Form>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.userReducer.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        sendOTPemail: (username) => dispatch(sendOTPemail(username)),
        messagesCleanUp: () => dispatch(messagesCleanUp()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(OTPForm);
