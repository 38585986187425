import Home from "./views/home/Home";
import React, { lazy } from 'react';
import MaidInsuranceSearch from "./components/InsuranceForm/BasicDetails/MaidInsuranceSearch";

const InsuranceHome = React.lazy(() => import('./views/InsuranceHome'));
const InsuranceView = React.lazy(() => import('./views/InsuranceView'));
const InsuranceDetails = React.lazy(() => import('./views/InsuranceDetails/InsuranceDetails'));
const SuccessView = React.lazy(() => import('./components/SuccessView/SuccessView'));
const SuccessPayment = React.lazy(() => import('./components/SuccessView/SuccessPayment'));
const PaymentSuccess = React.lazy(() => import('./pages/payment-success'));
const ClaimView = React.lazy(() => import('./views/ClaimView'));

const Logout = React.lazy(() => import('./components/Navbar/Logout'));
const SuccessClaim = React.lazy(() => import('./components/SuccessView/SuccessClaim'));
const RegisterAgents = React.lazy(() => import('./views/RegisterAgents'));
const Maid = React.lazy(() => import('./components/InsuranceForm/BasicDetails/MaidInsuranceSearch'));
const maidDetails = React.lazy(() => import('./components/InsuranceForm/BasicDetails/QBEOptionalCovers'));
const maidInsurenceView = React.lazy(() => import('./views/MaidInsuranceView'));
const MaidInsuranceFlow = React.lazy(() => import('./pages/maid-insurance'));
const MaidDetailedSummary = React.lazy(() => import('./pages/maid-insurance/step-three/retry-payment'));

const InsuranceSurvey = lazy(() => import("./views/InsuranceSurvey"));
const Dashboard = React.lazy(() => import('./views/Dashboard'));


//When adding new page, please also add the routes for the file as shown below.
const routes = [
    {
        exact: true,
        path: `/InsuranceSurvey/:type`,
        component: InsuranceSurvey
    },
    {
        exact: true,
        path: `/InsuranceHome`,
        component: InsuranceHome
    },
    {
        exact: true,
        path: `/InsuranceView/:id`,
        component: InsuranceView
    },
    {
        path: `/Dashboard`,
        component: Dashboard,
        authGuard : true
    },
    {
        exact: true,
        path: `/ClaimView/:id`,
        component: ClaimView
    },
    {
        exact: true,
        path: `/RegisterAgent`,
        component: RegisterAgents
    },
    {
        exact: true,
        path: `/InsuranceDetails/:id`,
        component: InsuranceDetails
    },
    {
        exact: true,
        path: `/Success/`,
        component: SuccessView
    },
    {
        exact: true,
        path: `/payment-status/`,
        component: PaymentSuccess
    },
    {
        exact: true,
        path: `/SuccessClaim/`,
        component: SuccessClaim
    },
    {
        path: `/logout`,
        component: Logout
    },
    {
        path: `/maid`,
        component: MaidInsuranceFlow
    },
    {
        path: `/maid-summary`,
        component: MaidDetailedSummary
    },
    {

        path: `/maidDetails`,
        component: maidDetails
    },
    {

        path: `/maidInsurenceView`,
        component: maidInsurenceView
    },
    {
        exact: false,
        path: `/`,
        component: Home
    },
];

export { routes };
