import React, {Component} from 'react';
import agiliuxAPI from "../../services/agiliuxAPI";
import IdleTimer from "react-idle-timer";
import {IdleTimeOutModal} from "../../components/IdleModal/IdleModal";
import {connect} from "react-redux";
import {autoLogin} from "../../redux/actions";
import {ProgressBarProvider} from "react-redux-progress/ProgressBarProvider";

import {notification} from "antd";
import {startAction, stopAction} from "../../redux/actions/userActions";

class Layout extends Component {

    constructor(props) {
        super(props)
        this.state = {
            timeout: 3600000,
            showModal: false,
            userLoggedIn: false,
            isTimedOut: false
        }
        this.idleTimer = null
    }

    openNotificationWithIcon = (type, code,  message) => {
        if (message && Object.keys(message).length !== 0) {
            message = message.replace(/"/g, '');
            let title;
            switch (code) {
                case 500: title = "500 (Internal Server Error)"; break;
                case 404: title = "404 (Not Found)"; break;
                default : title = " ";
            }
            notification[type]({
                message: title,
                placement : 'bottomRight',
                description: message,
            });
        }
    };

    componentWillMount () {
        this.reqInterceptor = agiliuxAPI.interceptors.request.use(
            async config => {
                this.props.startAction(config.url);
                if (this.props.accessToken) {
                    config.headers = {
                        'tenant_id': process.env.REACT_APP_TENANT_ID,
                        'Authorization': 'Bearer ' + this.props.accessToken,
                        'Accept': 'application/json',
                    }
                }else{
                    config.headers = {
                        'tenant_id': process.env.REACT_APP_TENANT_ID,
                        'Accept': 'application/json',
                    }
                }
                return config;
            });

        this.resInterceptor = agiliuxAPI.interceptors.response.use(res => {
            this.props.stopAction(res?.config?.url);
            return res?.data ? res.data : res;
        }, error => {
            this.props.stopAction(error?.config?.url ?? null);
            if(error.response && error.response.status == 500 && error.response.data) {
                let message = error.response.data.message ? error.response.data.message : "Service temporarily unavailable. Please try again later";
                console.log('MESSAGE: ', message);
                if (!message) {
                    message = "There has been an issue processing your request. Please try again.";
                }

                if (message?.message) {
                    message = message?.message;
                }

                if (message == 0) {
                    message = "There has been an issue processing your request. Please try again.";
                }
                if (message.startsWith('MSG')) {
                    message = message.substr(14);
                }
                this.openNotificationWithIcon('error', "",  message);
            } else {
                this.openNotificationWithIcon('error', "",  "Service temporarily unavailable. Please try again later");
            }
            return Promise.reject(error)
        });
    }

    //Initializing and checking timeout token on website load
    //Default timeout is set to 3 hours
    componentDidMount() {
        // this.props.autoLogin();
    }

    _onAction = (e) => {
        // console.log('user did something', e)
        this.setState({ isTimedOut: false })
    }

    _onActive = (e) => {
        // console.log('user is active', e)
        this.setState({ isTimedOut: false })
    }

    _onIdle = (e) => {
        // console.log('user is idle', e)
        const isTimedOut = this.state.isTimedOut
        if (isTimedOut) {
                    localStorage.clear();
                    window.location.href = `/logout`
                } else {
                    let userdata = JSON.parse(localStorage.getItem("user"));
                    if (userdata != null) {
                        this.setState({ showModal: true })
                        this.idleTimer.reset();
                        this.setState({ isTimedOut: true })
            }
        }
    }

    handleClose = (e) => {
        this.setState({ showModal: false })
    }

    handleLogout = (e) => {
        this.setState({ showModal: false })
        localStorage.clear();
        window.location.href = `/logout`
    }

    render () {
        return (
            <>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    onActive={this._onActive}
                    onIdle={this._onIdle}
                    onAction={this._onAction}
                    debounce={250}
                    timeout={this.state.timeout} />
                <IdleTimeOutModal
                    showModal={this.state.showModal}
                    handleClose={this.handleClose}
                    handleLogout={this.handleLogout}
                />
                <ProgressBarProvider color="#2f5597" />
                {this.props.children}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user : state.userReducer.user,
        accessToken : state.userReducer.accessToken,
        isAuthenticated:  state.userReducer.accessToken != null,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        autoLogin: () => dispatch(autoLogin()),
        startAction : (progressId) => dispatch(startAction(progressId)),
        stopAction : (progressId) => dispatch(stopAction(progressId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);

