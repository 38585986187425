import React from "react";
import { Element } from "react-scroll";
import { Card, Col, Container, Row } from "react-bootstrap";
import ico3 from "../../../assets/images/search.png";
import ico4 from "../../../assets/images/compare.png";
import ico5 from "../../../assets/images/Group 24.png";
import ico6 from "../../../assets/images/pay.png";
import ico7 from "../../../assets/images/privacy-policy.png";
import middle from "../../../assets/images/Group 102.png";
import leftImg from "../../../assets/images/Group 101.png";
import howItWorksImage from "../../../assets/images/Group 2050.png";


const Howitworks = () => {
  return (
    <Container fluid className="px-5">
      <div className="text-center"><h3 className="text-center"><strong>Instantly buy insurance and submit claims</strong></h3>
      <a style={{ color: '#2f5597' }}>See app in action <i className="fas fa-play-circle"></i></a>
      <div><img className="text-center" src={howItWorksImage} style={{ width: '75%' }} /></div>
      
      </div>
      {/* <Row className="main_row">
        <Col lg={12} className="p-0">
          <Card className="howworks justify-content-between">
            <Card.Body>
              <div className="middle-img">
                <img  src={middle} alt="" srcSet="" />
              </div>
              <div className="work_image2">
                      <img src={leftImg} alt="" srcSet="" />
                    </div>
              <Row className="text-center middleDiv justify-content-around flex-md-row flex-column">
                <div>
                  <img className="my-4" src={ico3} alt="" srcSet="" />
                  <p>Search</p>
                </div>
                <div>
                  <img className="my-4" src={ico4} alt="" srcSet="" />
                  <p>Compare</p>
                </div>
                <div>
                  <img className="mt-4 mb-3" src={ico5} alt="" srcSet="" />
                  <p>Request Quotation</p>
                </div>
                <div>
                  <img className="mt-4 mb-4" src={ico6} alt="" srcSet="" />
                  <p className="mt-3">Do Payment</p>
                </div>
                <div>
                  <img className="my-4" src={ico7} alt="" srcSet="" />
                  <p>Get Insurance!</p>
                </div>
              </Row>
            
              <div className="work_image">
                      <img src={middle} alt="" srcSet="" />
                    </div>
                   
                  
            </Card.Body>
          </Card>
        </Col>
      </Row> */}
    </Container>
  );
};

export default Howitworks;
