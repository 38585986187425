import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';
import {CLEAR_UP_SEARCH_FILTER} from "../actions/actionTypes";

const defaultState = {
    basicCoverageInfo : null,
    productList : [],
    selectedProduct : {},
    faqProduct : [],
    optionalPricing : {},
    optionalCovers : {
        care_allowance: "No",
        daily_hospitalcash: "No",
        indemnity_buyback: "No"
    },
    searchFilter : {},
    applicationReference: null
}

const setOptionalPricing = (state, action) => {
    return updateObject(state, {
        optionalPricing: action.optionalPricing
    })
}

const authStart = ( state, action ) => {
    return updateObject( state, { error: null, loading: true } );
};

const setOptionalCovers = (state, action) => {
    return updateObject(state, {
        optionalCovers: updateObject(state.optionalCovers, action.optionalCovers)
    })
}

const resetOptionalCovers = (state, action) => {
    return {
        ...state,
        optionalCovers: {
            care_allowance: "No",
            daily_hospitalcash: "No",
            indemnity_buyback: "No"
        }
    };
};

const setProductList = (state, action) => {
    return updateObject(state, {
        productList: action.productList
    })
}

const addProductList = (state, action) => {
    return updateObject(state, {
        productList: state.productList.concat(action.productList)
    })
}

const updateProductList = (state, action) => {
    let productList = [...state.productList];

    productList.forEach((part, index, theArray) => {
        let premiumProduct = action.productList.filter(product => theArray[index]['plancode'] === product['plancode']);
        return premiumProduct[0];
    });

    return updateObject(state, {
        productList: productList
    })
}

const setSelectedProduct = (state, action) => {
    return updateObject(state, {
        selectedProduct: action.selectedProduct
    })
}

export const setEtiqaAppRef = (state, action) => {
    return updateObject(state, {
        applicationReference: action.applicationReference
    })
};

export const setMSIGAppRef = (state, action) => {
    return updateObject(state, {
        applicationReference: action.applicationReference
    })
};

export const setFaqProduct = (state, action) => {
    return updateObject(state, {
        faqProduct: action.faqProduct
    })
}

export const setBasicInfo = (state, action) => {
    return updateObject(state, {
        basicCoverageInfo: action.basicInfo
    })
}

export const setSearchFilter = (state, action) => {
    return updateObject(state, {
        searchFilter: action.searchFilter
    })
}

export const clearUpSearchFilter = (state, action) => {
    return updateObject(state, {
        searchFilter: {}
    })
}

const userReducer = (state = defaultState, action) => {
    switch(action.type){
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.SET_PRODUCT_LIST: return setProductList(state,action);
        case actionTypes.ADD_PRODUCT_LIST: return addProductList(state,action);
        case actionTypes.UPDATE_PRODUCT_LIST: return updateProductList(state,action);
        case actionTypes.SET_SELECTED_PRODUCT: return setSelectedProduct(state,action);
        case actionTypes.SET_OPTIONAL_COVERS: return setOptionalCovers(state,action);
        case actionTypes.RESET_OPTIONAL_COVERS: return resetOptionalCovers(state, action);
        case actionTypes.SET_OPTIONAL_PRICING: return setOptionalPricing(state,action);
        case actionTypes.SET_FAQ_PRODUCT: return setFaqProduct(state,action);
        case actionTypes.SET_BASIC_COVERAGE_INFO: return setBasicInfo(state,action);
        case actionTypes.SET_SEARCH_FILTER: return setSearchFilter(state,action);
        case actionTypes.CLEAR_UP_SEARCH_FILTER: return clearUpSearchFilter(state,action);
        case actionTypes.ETIQA_APPLICATION_REF: return setEtiqaAppRef(state, action);
        case actionTypes.MSIG_APPLICATION_REF: return setMSIGAppRef(state, action);
        default: return state
    }
}

export default userReducer
