import React, {Component} from "react";
import AuthenticationModal from "./AuthModel/AuthenticationModal";
import MobileAuthModal from "./AuthModel/MobileAuthModal";
import {connect} from "react-redux";
import {Link as ReactLink, withRouter} from 'react-router-dom';
import {fetchUser, setCompanyLogo, setCurrentActiveTab} from "../../redux/actions/userActions";
import DefaultLogo from "../../assets/images/partners/AWG-logo-long-removebg-preview.png"
import airplane from "../../assets/images/airplane.svg";
import maid from "../../assets/images/maid.svg";
import car from "../../assets/images/car.svg";
import {Link, scroller} from 'react-scroll';
import {logout} from "../../redux/actions";
import LogoImage from "./Logo/LogoImage";
import Loader from '../Loader';
import {ProgressBarProvider} from 'react-redux-progress/ProgressBarProvider';
import {Menu, Popover} from 'antd';
import {DropdownButton, Dropdown, ButtonGroup} from 'react-bootstrap'
import {DownOutlined} from '@ant-design/icons';

const {SubMenu} = Menu;

class Navbar extends Component {
    state = {
        loggedIn: false,
        username: "",
        logo: "",
        isShowMobileInsurance : false,
    };

    componentDidMount() {
        this.props.setCompanyLogo();
    }

    logOut = (e) => {
        e.preventDefault()
        this.props.logout(this.props.accessToken);
        this.props.history.push('/');
    }

    handleSetActive = (to) => {
        this.props.setCurrentActiveTab(to);
    }

    handleSetInactive = (to) => {
        this.props.setCurrentActiveTab(null);
    }

    RouterLink = (name) => {
        if (this.props.location.pathname === "/") {
            return (<Link activeClass="active"
                          className="nav-link"
                          to={name} spy={true}
                          smooth={true}
                          duration={500}
                          onSetActive={this.handleSetActive}
                          onSetInactive={this.handleSetInactive}
                          style={{textTransform: 'capitalize'}}>{name}
            </Link>)
        } else {
            return (<ReactLink className="nav-link brand"
                               to="/"
                               onClick={() => this.handleSetActive(name)}
                               style={{textTransform: 'capitalize'}}>{name}
            </ReactLink>);
        }
    }

    handleLogoClickEvent = () => {
        if (this.props.location.pathname === "/") {
            scroller.scrollTo("about");
        } else {
            this.props.history.push('/');
        }
    }

    onClickDropdown = () => this.setState({isShowMobileInsurance : !this.state.isShowMobileInsurance})

    render() {
        const largeimage = this.props.logo ? this.props.logo : DefaultLogo;
        const buttonWidth = 70;
        const text = <span>Title</span>;
        const content = (
            <div className="mega-menu">
                    <ul className="insurance-submenu">
                      {/* <span className="mega-menu-header"><b>Personal    </b></span> */}
                        <li>         
                            <ReactLink className="d-flex flex-column align-items-center text-decoration-none" to="/">
                                <img width={"36px"} src={airplane}></img>
                                <span className="mt-3 font-weight-bold">TRAVEL</span>
                            </ReactLink>
                        </li>
                        <li>
                            <ReactLink className="d-flex flex-column align-items-center text-decoration-none" to="/maid">
                            <img width={"36px"} src={maid}></img>
                                <span className="mt-3 font-weight-bold">MAID</span>
                            </ReactLink>
                        </li>
                        <li>
                            <ReactLink className="d-flex flex-column align-items-center text-decoration-none" to="/">
                                <img width={"36px"} src={car}></img>
                                <span className="mt-3 font-weight-bold">MOTOR</span>
                            </ReactLink>
                        </li>
                      {/* <li><ReactLink to="/InsuranceSurvey/home">Personal Accidents</ReactLink></li> */}
                    </ul>
                {/* <div className="mm-4column">
                  <span className="categories-list">
                    <ul>

                      <li>Home Insurence</li>
                      <li>Car Insurence</li>
                      <li>Life Insurence</li>
                      <li>Travel Insurence</li>
                    </ul>
                  </span>
                </div> */}
                {/* <div className="mm-4column">
                  <span className="categories-list">
                    <ul>
                      <span className="mega-menu-header"><b>Corporate</b> </span>
                      <li>All Risks</li>
                      <li>Fire & Perils</li>
                      <li>Business Interruption</li>
                      <li>Professional Indemnity</li>
                    </ul>
                  </span>
                </div> */}
            </div>
        );
        let arrowClassList = ['arrow'];
        arrowClassList.push( this.state.isShowMobileInsurance ? 'up' : 'down');
        return (
          <>
            <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
                <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarsExampleDefault"
                    aria-controls="navbarsExampleDefault"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <ReactLink className="nav-link brand mobileLogo"
                                       to="/"
                                       onClick={this.handleLogoClickEvent}>
                                <LogoImage largeImgSrc={DefaultLogo}
                                           smallImgSrc={DefaultLogo}/>
                            </ReactLink>
                            <MobileAuthModal to="/"/>
                            {this.props.isAuthenticated ? (

                            <ReactLink to="/Dashboard"><i className="fas fa-user mobileProfile"></i></ReactLink>



                        ) : null}
                <div className="collapse navbar-collapse navbar " id="navbarsExampleDefault">
                    <ul className="navbar-nav menu">

                        <li className="nav-item mobileMenuHidden">
                            <ReactLink className="nav-link brand"
                                       to="/"
                                       onClick={this.handleLogoClickEvent}>
                                <LogoImage largeImgSrc={largeimage}
                                           smallImgSrc={DefaultLogo}/>
                            </ReactLink>
                        </li>

                        <li className="nav-item">
                            {this.RouterLink("home")}
                        </li>
                        <li className="nav-item">
                            <a href="https://www.awginsurance.com/" target="_blank">About Us</a>
                        </li>

                        <li className="nav-item mobileMenuHidden">
                            <Popover placement="bottom" content={content} trigger="hover">
                                <ReactLink className="nav-link popover-link"
                                           to="/"
                                           style={{textTransform: 'capitalize'}}>Insurance <i className="arrow down"></i>
                                </ReactLink>
                            </Popover>
                        </li>
                        <li className="nav-item d-lg-none">
                            <div className="dropdown1">
                                <a className="nav-link" onClick={this.onClickDropdown}>Insurance <i className={arrowClassList.join(' ')}></i> </a>
                                { this.state.isShowMobileInsurance ? <div className="dropdown1-body">
                                    <div className="dropdown1-content">
                                        <ul className="navbar-nav">
                                            <li>
                                                <span><a className="nav-link" style={{color: '#2f5597'}} href="#">Personal</a></span>
                                                <ul style={{listStyle: 'none', clear : 'both'}}>
                                                    <li><a className="nav-link" href="#">Travel Insurance</a></li>
                                                    <li><a className="nav-link" href="/maid">Maid Insurance</a></li>
                                                    <li><a className="nav-link" href="#">Motor Insurance</a></li>
                                                    <li><a className="nav-link" href="#">Personal Accidents</a></li>
                                                </ul>
                                            </li>
                                            {/* <li>
                                                <span><a className="nav-link" style={{color: '#2f5597'}} href="#">Corporate</a></span>
                                                <ul style={{ listStyle: 'none', clear: 'both' }}>
                                                    <li>All Risks</li>
                                                    <li>Fire & Perils</li>
                                                    <li>Business Interruption</li>
                                                    <li>Professional Indemnity</li>
                                                </ul>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div> : null }
                            </div>
                        </li>

                        <li className="nav-item">
                        <ReactLink className="nav-link"
                                           to="/"
                                           style={{textTransform: 'capitalize'}}>Claims
                                </ReactLink>
                        </li>
                        <li className="nav-item">
                            <a href="#contact">Contact Us</a>
                            {/* {this.RouterLink("contact Us")} */}
                        </li>

                        <AuthenticationModal to="/Dashboard"/>
                        {this.props.isAuthenticated ? (
                            <li className="nav-item dropdown active">
                                <a
                                    className="nav-link dropdown-toggle"
                                    href="#"
                                    id="dropdown01"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <i className="fas fa-user mr-2"></i>{"Welcome, " + this.props.username + "!"}
                                </a>
                                <div className="dropdown-menu" aria-labelledby="dropdown01">
                                    <ReactLink className="dropdown-item" to="/Dashboard">
                                        <i className="fas fa-user mr-2"></i>My Dashboard
                                    </ReactLink>
                                    <a className="dropdown-item" href='javascript:void(0)' onClick={this.logOut}>
                                        <i className="fas fa-power-off mr-2"></i>Logout
                                    </a>
                                </div>
                            </li>
                        ) : null}
                    </ul>
                </div>
                <ProgressBarProvider isActive={this.props.isActive} color="#292457"/>
            </nav>
            {this.props.isActive && <Loader />}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isActive: state.progress.isActive,
        userReducer: state.userReducer,
        logo: state.userReducer.logo,
        username: state.userReducer.user.firstname+' '+state.userReducer.user.lastname,
        accessToken: state.userReducer.accessToken,
        isAuthenticated: state.userReducer.accessToken != null,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUser: (userInfo) => dispatch(fetchUser(userInfo)),
        logout: (token) => dispatch(logout(token)),
        setCurrentActiveTab: (tabName) => dispatch(setCurrentActiveTab(tabName)),
        setCompanyLogo: () => dispatch(setCompanyLogo()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withRouter(Navbar)));
