import * as actionTypes from './actionTypes';
import {cleanUpObject} from "../../shared/utility";
import agiliuxAPI from "../../services/agiliuxAPI";
import {authStart, setUser, startAction, stopAction} from "./userActions";
import {setOptionalCovers, setOptionalPricing, setProductList} from "./productActions";

export const setQuotes = (quotes) => ({ type: actionTypes.SET_QUOTES, quotes : quotes  })

export const setWorker = (worker) => ({ type: actionTypes.SET_WORKER, worker : worker  })

export const setAccount = (account) => ({ type: actionTypes.SET_ACCOUNT, account : account  })

export const setQuestionAns = (questionAns) => ({ type: actionTypes.SET_QUESTION_ANS, questionAns : questionAns  })